
<script>
import { getLocalTime } from '@/utils';
export default {
    name: "RowsTable",
    props: {
        rows: Number,
        employee: Object
    },
    methods:{
        getLocalTime(date){
            return getLocalTime(date)[0]
        }
    }
   
}
</script>

<template>
    <tr class="rows-table">
        <td>
            <p id="col1">{{ rows }}</p>
        </td>
        <td style="text-align: left;">

            <router-link style="display:block;padding-left:5px;" class="bottom_links"
             :to="{ name: 'yuristProfile', params: { employeeId: employee.id } }">
             {{employee.name}}</router-link>
        </td>
        <td>{{ employee.rating }}</td>
        <td>{{ employee.his_clients.length}}</td>
        <td>{{ employee.city }}</td>
        <td>{{ employee.total_sum_contract }}</td>
        <td>{{ employee.obligation }}</td>
    </tr>
</template>

<style scoped>
tr>td {
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    padding: 8px 0;
}

tr>td:last-child {
    border-right: 0;
}
</style>