import { createApp } from 'vue'
import App from './App.vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css'

import "@/assets/styles.css"
import "@/assets/font/font-awesome-4.7.0/css/font-awesome.min.css"


import router from "./router"
import store from "./store"


const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App);
app.use(store)
app.use(router)
app.use(vuetify)

app.mount('#app')

app.directive('phone', {
    mounted(el) {
      el.setAttribute('placeholder', '+7 (____) ___ __ __');
      el.setAttribute('maxlength', '18');
      el.setAttribute('type', 'text');
      el.addEventListener('keyup', function (event) {
        let x = event.target.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  
        x[1] = '+7'
        let formattedPhoneNumber = !x[3] ? x[1] + ' (' + x[2] : x[1] + ' (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '')
        event.target.value = formattedPhoneNumber;
      });
    },
  });
