<!-- личный кабинет юриста -->
<script>
import RatingUser from "@/components/rating-user.vue";
import vFlags from "../../v-flags.vue"
import infoContainer from "@/components/info-container.vue";

import { mapActions, mapGetters } from "vuex";
import { logout_from_profile, getLocalTime, download, formatName } from '@/utils'
import { baseURL } from '@/config';
import axios from "@/roleGuard"
import vNavigationPanel from "@/components/v-navigation-panel.vue";
export default {
    name: "lkYurist",


    components: {
        vFlags,
        infoContainer,
        RatingUser, vNavigationPanel
    },

    data() {
        return {

            messages: [],
            my: {},
            additionally_data: {},
            my_clients: {},
            searchClient: '',
            filtered: [],
            search_client_show: false,

        }
    },
    computed: {
        ...mapGetters('main', ['getUser']),

    },
    methods: {
        ...mapActions('main', ['GET_USER_INFO']),

        logout() {
            logout_from_profile(this.$router)
        },
        clearInputClient() {
            this.filtered = [];
            this.search_client_show = false
            this.searchClient = ''
        },
        formated(fullName) {
            return 'Здравствуйте, ' + formatName(fullName) + '!'
        },

        loadAdditionallyData(id) {
            const url = baseURL + `employee/${id}`

            axios.get(url)
                .then(response => {
                    this.additionally_data = response.data
                    this.my_clients = response.data.his_clients
                }
                )
                .catch(e => console.log(e))
        },
        filteredClients() {
            if (this.my_clients) {
                if (this.searchClient.trim() === '') {
                    this.filtered = []; // Если поле ввода пусто, очистите результат
                    this.search_client_show = false
                    return;
                }
                this.search_client_show = true
                // Фильтрация клиентов на основе введенного текста
                const filtered = this.my_clients.filter(client =>
                    client.name.toLowerCase().includes(this.searchClient.toLowerCase())
                );

                this.filtered = filtered
            }

        },
        getDate(server_date) {
            return getLocalTime(server_date)[0]
        },
        downloadUserFile() {
            const id = this.my.id
            const url = baseURL + `user/${id}/download-file`
            download(url)
        },


    },
    created() {
        this.my = this.getUser
        this.loadAdditionallyData(this.my.profile.id);
    },


}
</script>


<template>
    <div class="lk-page" v-if="getUser">
        <vFlags :text_flags="formated(getUser.profile.name)"></vFlags>
        <vNavigationPanel :showPrevLink="false"></vNavigationPanel>

        <div class="content">

            <div class="content-left">
                <p class="lk_input_p">Быстрый поиск клиента</p>
                <input type="text" class="lk_input" v-model="searchClient" @input="filteredClients"
                    placeholder="Введите фио клиента...">
                <div class="result_search" v-if="this.search_client_show">
                    <p class="result-a" style="font-family: pro; " v-if="!filtered.length" @click="clearInputClient()">
                        Очистить резльтаты поиска</p>
                    <router-link class="result-a" v-for="client in filtered" :key="client.id"
                        :to="{ name: 'watchClient', params: { clientId: client.id } }">{{ client.name }}</router-link>
                </div>

                <ul class="lk_navigation">
                    <RouterLink :to="{ name: 'Создание клиента' }" class="bottom_line" href="">> Создать нового клиента
                    </RouterLink>
                    <RouterLink :to="{ name: 'clientSpisok' }" class="bottom_line" href="">> Список всех клиентов
                    </RouterLink>
                    <RouterLink :to="{ name: 'ratingYurist' }" href="">> Рейтинг юристов компании</RouterLink>
                </ul>

                <div class="messages">
                    <!-- Здесь будут сообщения -->
                    <infoContainer></infoContainer>
                </div>

            </div>

            <div class="content-right">
                <div class="content-right__info">
                    <ul class="content-right__ul" v-if="my.profile">
                        <li class="">Имя: {{ my.profile.name }}</li>
                        <li class="">Дата рождения: {{ this.getDate(my.profile.date_of_birth) }}</li>
                        <li class="">Телефон: {{ my.profile.number_phone }}</li>
                        <li class="">E-mail: {{ my.profile.email }}</li>
                        <li class="">Город работы: {{ my.profile.city }}</li>
                        <li class="">Трудовой договор: № {{ my.profile.number_contract }}</li>
                        <li class="">Дата заключения: {{ this.getDate(my.profile.date_start_contract) }}г.</li>
                        <li>
                            <div class="download_contract" v-if="additionally_data.file_pdf_contract">
                                <span class="download_contract-text" ref="fileText"> Договор:
                                    <span @click="downloadUserFile()" class="download_contract-icon"
                                        style="float:right;"></span>
                                    {{ additionally_data.file_pdf_contract }}
                                    <br>
                                </span>

                            </div>
                        </li>
                        <li class="">Договоров у юриста: {{ additionally_data.active_contract }}</li>
                        <li class="">Заключено на сумму: {{ additionally_data.total_sum_contract }}</li>
                        <li class="">Должники: {{ additionally_data.obligation }}</li>
                        <li
                            style="background: white;color:black;border-bottom-left-radius:15px;border-bottom-right-radius:15px;">
                            Дополнительно:
                            {{ my.profile.additionally }}
                        </li>
                    </ul>
                </div>
                <p>
                    <RouterLink to="/employee/change" href="" class="bottom_links">редактировать данные</RouterLink>
                </p>
                <p><a href="" class="bottom_links" style="color:var(--yellow)" @click="logout()">выйти из кабинета</a></p>

                <RatingUser :title="'мой рейтинг'"  :count="additionally_data.rating"></RatingUser>
            </div>
        </div>
    </div>
</template>


<style scoped>
.result_search {
    text-align: left;
    padding: 10px;
    font-size: 18px;
}
</style>