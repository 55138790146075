<script>
import vFlags from "../v-flags.vue"
import vNavigationPanel from '../v-navigation-panel.vue';
export default {
    name: "authPage",
    components: {
        vFlags,vNavigationPanel
    },
    data() {
        return {
            title: "Вход в кабинет"
        }
    }
}
</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel :showPrevLink = 'false'> </vNavigationPanel>

        <div class="flex-center">
           <router-view>
           </router-view>
        </div>
    </div>
</template>

<style scoped></style>