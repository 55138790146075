
<script>

export default{
    name:"RowsTable",
    props:{
        rows:Number,
        employee:{}
    }
}
</script>

<template>

    <tr class = "rows-table">
        <td><p id = "col1">{{ rows }}</p></td>
        <td style = "text-align: left;padding-left:5px ;">  {{ employee.name }}</td>
        <td>{{ employee.rating }}</td>
        <td>{{ employee.active_contract}}</td>
        <td>{{ employee.city }}</td>
    </tr>

</template>

<style scoped>
    tr>td{
        border-right:2px solid white;
        border-bottom: 2px solid white;
        padding:8px 0;
    }
    tr>td:last-child{
        border-right:0;
    }
    
    
</style>