<script>
import axios from 'axios'
import { baseURL } from '@/config';
import { mapActions, mapMutations } from 'vuex';
// import authMessage from './auth-message.vue';


export default {
    name: "authForm",
    components: {
        
    },
    data() {
        return {
            email: "",
            password: "",
        }
    },
    methods: {
        ...mapActions(['loadCities']),
        ...mapMutations('main', ['setEmail']),
        ...mapMutations('request', ['setResponse']),
        login() {
            this.$refs.btn.setAttribute("disabled", '')
            this.$refs.btn.innerText = "ЗАПРОС ОТПРАВЛЕН"
            const url = baseURL + 'auth/login';
            const data = {
                email: this.email,
                password: this.password
            }
            axios.post(url, data)
                .then(() => {
                    this.setEmail(this.email)
                    this.$router.replace({ name: "authConfirm", })
                })
                .catch((e) => {
                    this.setResponse({status:false, message:e.response.data.message})
                    this.$refs.btn.innerText = "ВХОД В КАБИНЕТ"
                    this.$refs.btn.removeAttribute("disabled", '')

                })
        }

    },
    beforeMount() {
        this.loadCities()
    }
}

</script>

<template>
    <div class="auth-form">

        <p class="clue"><b style="font-weight: 800;">1 шаг</b>: введите свой E-mail и пароль </p>
        <form action="" class="auth-form__form" @submit.prevent="login()">
            <p class="input_text">Введите свой E-mail</p>
            <input v-model="email" type="text" class="auth-form__inp" placeholder="E-mail" required>
            <p class="input_text">Введите свой пароль</p>
            <input v-model="password" type="password" class="auth-form__inp" placeholder="Password" required>
            <button class="lk__btn" ref="btn">
                ВХОД В КАБИНЕТ
            </button>

            <router-link :to="{ name: 'authRestore' }" href="">
                <p style="font-family: regular; font-weight: 800;">Забыли свой пароль?</p>
            </router-link>
        </form>
    </div>
</template>

<style>
.auth-form {
    text-align: center;
    margin: auto auto;
    color: white;
    font-family: regular;
}

.clue {
    padding: 13px;
    font-family: regular;
}

.auth-form__form {
    margin: auto auto;
    max-width: 450px;
    background: var(--yellow);
    border-radius: 17px;
    padding: 10px;
    margin-bottom: 10px;
}

.input_text {
    text-align: left;
    color: white;
    font-style: bold;
    font-family: pro;
    padding: 10px 5px 5px;
}

.auth-form__inp {
    font-size: 18px;
    width: 100%;
    padding-left: 10px;
    height: 50px;
    border-radius: 11px;
    border: 0;
    outline: none;
}
</style>