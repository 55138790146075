
<script>
import { getLocalTime } from '@/utils';
export default {
    name: "rowsForspisok",
    props: {
        rows: Number,
        client: Object
    },
    methods:{
        getLocalTime(date){
            return getLocalTime(date)[0]
        }
    }
}
</script>

<template>
    <tr class="rows-table">
        <td>
            <p id="col1">{{ rows }}</p>
        </td>
        <td style="text-align: left;">
            <router-link :to="{ name: 'watchClient', params: { clientId: client.id } }" class="bottom_links" style=" text-align:center;"> {{ client.name }}</router-link></td>
        <td> {{ this.getLocalTime(client.date_start_contract )}}</td>
        <td> {{ client.rating }}</td>  
        <td> {{ client.contract_amount }}</td>
        <td> {{ client.contract_amount - client.paid}}</td>
    </tr>
</template>

<style scoped>
tr>td {
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    padding: 8px;
    text-align:center;
}

tr>td:last-child {
    border-right: 0;

}
</style>