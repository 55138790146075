<!-- личный кабинет клиента -->

<script>
import { mapGetters, mapActions } from "vuex";
import vFlags from "../../v-flags.vue"
import infoContainer from "@/components/info-container.vue";
import { logout_from_profile,download, getLocalTime, formatName } from '@/utils';
import axios from '@/roleGuard'
import { baseURL } from "@/config";
import vNavigationPanel from "@/components/v-navigation-panel.vue";
export default {
    name: "lkClient",
    components: {
        vFlags,
        infoContainer,vNavigationPanel
    },

    data() {
        return {
            my: {
                name: "Клиент",
                date_change_rating: "00-00-0000 00-00"
            },
        }
    },
    computed: {
        ...mapGetters('main', ['getUser']),
       
        getDateUpdateRating(){
            return getLocalTime(this.my.date_change_rating)
        },
        
        
    },
    methods: {
        ...mapActions('main',['GET_USER_INFO']),
        formated(fullName) {
            return 'Здравствуйте, ' + formatName(fullName) + '!'
        },
        removeNonNumeric(inputString) {
            if(inputString){
            return 'tel:' + inputString.replace(/[\s()-]/g, '');}
        },
        logout() {
            logout_from_profile(this.$router)
        },
        getDate(server_date){
            return getLocalTime(server_date)[0]
        },
        getMoreInfo(id) {
            const url = baseURL + `client/${id}`
            axios.get(url)
                .then((response) => { this.my = response.data })
                .catch(e => console.log(e))
        },
       
        calculateColor(count) {
            let color = '';
            if (count === 0) { return }
            if (count > 8.5) {
                color = 'var(--green)'
            }
            else if (count < 8.5 && count >= 6) {
                color = 'var(--mongo)'
            }
            else {
                color = 'var(--red)'
            }
            return `${color}`;
        },
        updateRating() {
            const url = baseURL + 'messages/rating/update'

            axios.put(url, { rating: this.my.rating })
                .then(() => { })
                .catch(e => console.log(e))
        },
        downloadUserFile() {
            const id = this.my.user
            const url = baseURL + `user/${id}/download-file`
            download(url)
        },


    },
    created() {
        // this.GET_USER_INFO();
        this.my = this.getUser
        this.getMoreInfo(this.my.profile.id);

    },  
}
</script>


<template v-if="getUser">
    <div class="lk-page" v-if="my.name">
        <vFlags :text_flags="formated(my.name)"></vFlags>
        
        <vNavigationPanel :showPrevLink="false"></vNavigationPanel>
        <div class="content">

            <div class="content-left">




                <div class="head-info">
                    <h2>Ваш юрист:</h2>
                    <p>{{ my.lawyer_name }}</p>
                </div>
                <a :href="removeNonNumeric(my.lawyer_number_phone)">
                    <div class="number_phone">
                        <span class="">позвонить юристу</span>
                        <span class="">{{ my.lawyer_number_phone  || 'нет информации' }}</span>
                    </div>
                </a>

                <div class="messages">
                    <!-- Здесь будут сообщения -->
                    <infoContainer></infoContainer>
                </div>
            </div>
            <div class="content-right">
                <div class="content-right__info">
                    <ul class="content-right__ul">
                        <li class="">Имя: {{ my.name }}</li>
                        <li class="">Дата рождения: {{ this.getDate(my.date_of_birth) }} г.</li>
                        <li class="">Телефон: {{ my.number_phone }}</li>
                        <li class="">E-mail: {{ my.email }}</li>
                        <li class="">Город обращения: {{ my.city }}</li>
                        <li class="">Номер договора: № {{ my.number_contract }}</li>
                        <li>
                            <div class="download_contract" v-if="my.file_pdf_contract">
                                <span class="download_contract-text" ref="fileText"> Договор:       <span @click="downloadUserFile()" class="download_contract-icon" style="float: right;"></span> <br>{{ my.file_pdf_contract}}
                            </span>
                          
                            </div>
                        </li>

                        <li class="">Дата заключения: {{ this.getDate(my.date_start_contract) }} г.</li>
                        <li class="">Сумма по договору: {{ my.contract_amount }}</li>
                        <li class="">Оплата по договору: {{ my.paid }}</li>
                 

                        <li
                            :style="my.status_contract ? { 'backgroundColor': 'grey' } : { 'backgroundColor': 'var(--green)' }">
                            Статус: {{ my.status_contract ? "закончено" : "в работе" }}
                        </li>

                        <li
                            style="background: white;color:black;border-bottom-left-radius:15px;border-bottom-right-radius:15px;">
                            Вопрос обращения: {{ my.additionally }}</li>
                    </ul>
                </div>
                <p>
                    <RouterLink to="/client/changeprofile" href="" class="bottom_links">редактировать данные</RouterLink>
                </p>
                <p><a href="" class="bottom_links" style="color:var(--yellow)" @click="logout()">выйти из кабинета</a></p>


                <div class="review">
                    <div>
                        <h3 class="review-main-text">Насколько Вы довольны работой?</h3>
                        <p class="review__p">пожалуйста, поставьте или измените оценку работы специалиста. Так Вы помогаете
                            нам стать лучше:</p>

                        <div class="rating-user ">
                            <div class="" :style="{ background: calculateColor(my.rating) }">
                                <p class="title">ваша оценка</p>
                                <div class="costum-select">
                                    <select name="" id="" :disabled = "my.status_contract" v-model="my.rating" @change="updateRating()">
                                        <option v-for="i in 10" :key="i" :value="i">{{ i }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <p class="review__p">обновлено в {{ getDateUpdateRating[0] }} в {{
                           getDateUpdateRating[1] }} </p>

                    </div>

                    <a class="review-main-text" href="https://t.me/zabyurist75"  target="_blank">Есть вопросы? Напишите нам напрямую в телеграм</a>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.number_phone{
    
    background-color:var(--mongo);
    border-radius: 13px;
    padding: 5px;
    font-size:18px;
    display:flex;
    flex-wrap: wrap;
    /* grid-gap:3px; */
    justify-content: space-between;
    align-items: center;
}

.number_phone span:first-child{
    margin:auto;
    font-size:1.2em;
}
.number_phone span:last-child{
    background-color: white;
    color:black;
    border-radius: 9px;
    padding:5px 10px;

}
@media (max-width: 465px) {
    .number_phone span {
        width: 100%; /* Устанавливаем ширину 100% */
    }
}
.number_phone span{
    padding:3px 0;
    font-family: regular;
}


.head-info {
    text-align: left;
    padding: 10px;
}


.head-info>p {
    font-size: 22px;
}

.review {
    margin: 15px 0;
}

.review>div {
    background-color: var(--black);
    padding: 10px 5px;

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.review__p {
    text-align: 0;
    width: 100%;
    font-size: 15px;
}

.review-main-text {
    font-size: 19px;
    padding: 2px 20px;
}


.review a {
    display: block;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 20px;
    width: 100%;
    background: var(--light-blue);
    cursor: pointer;
}

.rating-user {

    width: 100%;
    /* padding: 15px 0; */
    border-radius: 10px;
    font-size: 20px;
    margin: 5px 0;
    
}


.rating-user>div {
    display: table;
    /* justify-content: space-between; */
    /* align-items: center; */
    background: var(--green);
    border-radius: 13px;
    max-width: 270px;
   
    margin: 0 auto;
    padding: 5px;
}

.title {
    /* font-family: regular;
    font-weight: 400; */
    display: table-cell;
	padding: 5px

}


.costum-select {
    /* margin: 5px 10px; */
    width: 90px;
	display: table-cell;
	border-radius: 9px

}

.costum-select select {
    font-size: 22px;
    padding-right: 0;
    height: 40px;
    padding-left: 40px;
}
</style>