<script>
import { isEmployee } from '@/utils';
export default {
    name: "ratingUser",

    props: {
        title: String,
        count: Number,
      
    },
    computed:{
     
    },
    methods: {
        calculateColor(count) {
            let color = '';

            if(count>8.5){
                color = 'var(--green);'
            }
            else if(count<8.5 && count>6){
                color = 'var(--mongo);'
            }
            else{
                color = 'var(--red);'
            }
            let style = ''
            if(isEmployee()){
                style =  'max-width:230px;'
            }
            else{
                style  = 'max-width:270px;'
            }

            return `background-color:${color}${style}`;
        }
    }
}

</script>

<template>
    <div class="rating-user">
        <div class="" :style=" calculateColor(count) ">
            <span class="title">{{ title }}</span>
            <span class="count">{{ count }}</span>
        </div>
    </div>
</template>


<style scoped>
.rating-user {
    width: 100%;
    border-radius: 10px;
    background: var(--black);
    font-size: 20px;
    /* margin: 20px 0; */
    padding: 15px 0;
}

.rating-user>div {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--green); */
    border-radius: 13px;
    max-width: 265px;
    width: auto;
    grid-gap:5px ;
    margin: 0 auto;
    padding: 5px;
}

.title {
    font-family: regular;
    padding: 5px;
}

.count {
    background: white;
    color: black;
    border-radius: 9px;
    /* margin-right:-20px; */
    font-size: 22px;
    height: 40px;
    padding: 5px 25px;
}
</style>