<script>
import { getWidthWindow, breakPoints } from '@/utils';

export default {
    name: "vFlags",
    data(){
        return {width:"", style:""}
    },
    props: {
        text_flags: String,
        
    },
    computed:{
       
    },
    methods:{
        changeFlagsSize(){
            let width = getWidthWindow()
            let style;
            if(width > breakPoints[2]){style = "margin: 30px -48px 25px;"}
            else if (width < breakPoints[2] && width > breakPoints[1]){
                 style = 'margin:0 -35px 25px;margin-top:0'
            }
            else if(width<880){

                style = 'margin:0 -25px 25px; margin-top:0'
            }
            else{
            style = 'margin: 0 -29px 25px;'}
            return style
        },
        handleResize() {
            this.width = getWidthWindow()
            this.style =this.changeFlagsSize()
        }
    },
    created() {
        this.width = getWidthWindow()
        this.handleResize()
        window.addEventListener('resize', this.handleResize);

    },
}
</script>

<template>
    <div class="v-flags" :style = "style">
        <h1>{{ text_flags}}</h1>
    </div>
</template>

<style scoped>
.v-flags {
    background: var(--blue);
    box-shadow: 0 5px 0 0 rgba(0, 0, 0, .2);
    border-radius: 11px;
    text-align:left;
    color:white;
    font-family: pro;
    padding: 5px 10px 5px 25px;
    margin: 30px -48px 25px;
}

h1 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 34px;
}

@media (max-width:400px){
    h1 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 25px;
}
}
</style>