import { createWebHistory, createRouter } from "vue-router";
import { adminGuard, yuristGuard, clientGuard, checkRoleAndTokenGuard, adminAndEmployeeGuard } from './roleGuard.js'; // Импортируем гвард , checkRoleAndTokenGuard

/*все компаненты */

import authPage from "./components/auth/auth-page.vue";
import authForm from "./components/auth/auth-form.vue";
import authRestore from "./components/auth/auth-restore.vue";
import authConfirm from "./components/auth/auth-confirm.vue";
import changeSettings from "./components/change-settings.vue"
import watchClient from "./components/watch-client.vue";
import accessDenied from "./components/access-denied.vue";

import lkAdmin from "./components/lk/admin/lk-admin.vue"
import creeateYurist from "./components/lk/admin/create-yurist.vue"
import createClient from "./components/lk/admin/create-client.vue"
import clientAdminspisok from "./components/lk/admin/client-adminspisok.vue";
import adminRating from "./components/lk/admin/admin-rating.vue";
import changeYuristprofile from "./components/lk/admin/change-yuristprofile.vue"
import adminChangeclient from "./components/lk/admin/admin-changeclient.vue"
import yuristProfile from "./components/lk/admin/yurist-profile.vue"


import lkYurist from "./components/lk/yurist/lk-yurist.vue"
import ratingYurist from "./components/lk/yurist/rating-yurist.vue";
import yuristCreateclient from "./components/lk/yurist/yurist-createclient.vue"
import yuristChangeclient from "./components/lk/yurist/yurist-changeclient.vue"
import clientSpisok from "./components/lk/yurist/client-spisok.vue"


import lkClient from "./components/lk/client/lk-client.vue"



const routes_all = [
    /**для всех пользователей*/
    {
        path: "/",
        component: authPage,
        children: [
            {
                path: "authForm",
                name: "authForm",
                component: authForm

            },
            {
                path: "authConfirm",
                name: "authConfirm",
                component: authConfirm,
            },
            {
                path: "authRestore",
                name: "authRestore",
                component: authRestore
            },
            {
                path: "/access-denied",
                component: accessDenied
            },
        ],
        beforeEnter: checkRoleAndTokenGuard,
        meta: { title: "Авторизация" }

    },

]

const routes_admin_employee = [
    {
        path: "/client-profile/:clientId",
        name: "watchClient",
        component: watchClient,
        beforeEnter: adminAndEmployeeGuard,
        meta: { title: "Профиль клиента" }
    },
]
const routes_admin = [
    {
        path: "/admin/change",
        component: changeSettings,
        meta: { title: "Изменение данных" }
    },

    //admin запретим маршруты в before mount
    {
        path: "/admin/",
        name: "lkAdmin",
        component: lkAdmin,
        meta: { title: "Личный кабинет админа" }
    },
    {
        path: "/admin/create-emplyee/",
        name: "createYurist",
        component: creeateYurist,
        meta: { title: "Создание юриста" }
    },
    {
        path: "/admin/create-client/",
        name: "createClient",
        component: createClient,
        meta: { title: "Создание клиента" }
    },
    {
        path: "/admin/client-list/",
        name: "clientAdminspisok",
        component: clientAdminspisok,
        meta: { title: "Список клиентов" }
    },
    {
        path: "/admin/rating/",
        name: "adminRating",
        component: adminRating,
        meta: { title: "Рейтинг юристов" }
    },
    {
        path: "/admin/change-employee/:employeeId/",
        name: "changeYuristprofile",
        component: changeYuristprofile,
        meta: { title: "Изменение данных юриста" }
    },
    {
        path: "/admin/change-client/:clientId/",
        name: "adminChangeclient",
        component: adminChangeclient,
        meta: { title: "Изменение данных клиента" }
    },

    {
        path: "/employee-profile/:employeeId/",
        name: "yuristProfile",
        component: yuristProfile,
        meta: { title: "Профиль юриста" }
    },
    {
        path: "/employee/:employeeId/clients/",
        name: "employeeClientSpisok",
        component: clientSpisok,
        meta: { title: "Список клиентов юриста" }
    },

]
//все маршруты для юриста
const routes_specialist = [
    {
        path: "/employee/change/",
        component: changeSettings,
        meta: { title: "Изменение данных" }

    },
    {
        path: "/employee/",
        name: "lkYurist",
        component: lkYurist,
        meta: { title: "Личный кабинет юриста" }
    },
    {
        path: "/employee/create-client",
        name: "Создание клиента",
        component: yuristCreateclient,
        meta: { title: "Создание клиента" }
    },
    {
        path: "/employee/clients/",
        name: "clientSpisok",
        component: clientSpisok,
        meta: { title: "Список клиентов" }
    },
    {
        path: "/employee/rating/",
        name: "ratingYurist",
        component: ratingYurist,
        meta: { title: "Рейтинг юристов" }
    },
    {
        path: "/employee/change-client/:clientId/",
        name: "yuristChangeclient",
        component: yuristChangeclient,
        meta: { title: "Изменение данных клиента" }
    },

]
//все маршруты для клиента
const routes_client = [
    {
        path: "/client/changeprofile",
        name: "client",
        component: changeSettings,
        meta: { title: "Изменение данных" }

    },
    {
        path: "/client/",
        name: "lkClient",
        component: lkClient,
        meta: { title: "Личный кабинет клиента" }
    },
];


routes_admin.forEach(route => {
    route.beforeEnter = adminGuard; // Применяем гвард администраторов
});

routes_specialist.forEach(route => {
    route.beforeEnter = yuristGuard; // Применяем гвард юристов
});
routes_client.forEach(route => {
    route.beforeEnter = clientGuard; // Применяем гвард администраторов
});

const pageNotFound = [

    { 
        path: '/:pathMatch(.*)*',
        name:"not-found",
        component: ()=>import("./components/pageNotFound.vue")
    }
]
// Объединяем все маршруты в один массив
export const allRoutes = [

    ...routes_all,
    ...routes_admin,
    ...routes_specialist,
    ...routes_client,
    ...routes_admin_employee,
    ...pageNotFound
];

const router = createRouter({
    history: createWebHistory(),
    routes: allRoutes
});

export default router;