<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import vFlags from '@/components/v-flags.vue';
import rowsTable from '@/components/rows-table.vue';
import { clearFilter } from '@/utils'
import vNavigationPanel from "@/components/v-navigation-panel.vue";
import Paginate from "vuejs-paginate-next"
export default {
    name: "adminRating",
    components: {
        vFlags,
        rowsTable, vNavigationPanel, Paginate
    },
    data() {
        return {
            title: "Рейтинг юристов",
            currentPage: 1,
            employeePerPage: 5,
            // Надо будет уудалить 
            filter: {
                city: '',
                count_clients: false,
                lowest_rating: false,
                total_sum_contract: false,
                obligations: false
            }
        }
    },
    computed: {
        ...mapGetters('main', ['getCompanyInfo', 'allCities', 'allEmployees', 'getFilteredEmployees']),
        displayedEmployee() {
            const startIndex = (this.currentPage - 1) * this.employeePerPage;
            const endIndex = startIndex + this.employeePerPage;
            return this.getFilteredEmployees.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.getFilteredEmployees.length / this.employeePerPage);
        }
    },
    methods: {
        ...mapMutations('main', ['setFilteredEmployees']),
        ...mapActions('main', ['loadCities', 'GET_INFO_COMPANY', 'GET_EPLOYEES_FROM_API']),
        removeFilter() {
            clearFilter(this.filter)
            this.setFilteredEmployees(this.allEmployees)
            this.filter.city = ''
        },

        goToPage(pageNumber) {
            this.currentPage = pageNumber;

        },

        ratingFilter() {
            const sort = this.getFilteredEmployees.sort((a, b) => b.rating - a.rating);
            this.setFilteredEmployees(sort)
            this.currentPage = 1
        },
        filterByCountClients() {

            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredEmployees, [function (user) { return user.his_clients.length }]);
            this.setFilteredEmployees(sort.reverse())
            this.currentPage = 1
        },


        filterByCity() {

            const _ = require('lodash');
            const sort = _.filter(this.allEmployees,
                ['city', this.filter.city]);

            this.setFilteredEmployees(sort)
            this.currentPage = 1
        },

    },
    beforeMoun() {
        this.ratingFilter()
    },
    created() {
        this.loadCities()
        this.GET_EPLOYEES_FROM_API()
    }
}

</script>

<template>
    <div class="lk-page">
        <div class="rating-yurist">
            <vFlags :text_flags="title"></vFlags>
            <vNavigationPanel></vNavigationPanel>

            <div class="filter">
                <p>фильтр рейтинга:</p>

                <div style="margin:10px 0">
                    <div>
                        <div class="filter__btn">
                            <span class="title">выбрать город</span>
                            <div class="costum-select">
                                <select name="" id="" class="" v-model='filter.city' @change="filterByCity">
                                    <option value="" disabled>Все города</option>
                                    <option v-for="city in allCities" :key="city.id" :value="city.city">{{ city.city }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.lowest_rating"
                            @input="ratingFilter()">
                        <label for="ch1">рейтинг</label>
                    </span>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox " v-model="filter.count_clients"
                            @change="filterByCountClients()">
                        <label for="ch1">клиенты</label>
                    </span>

                    <span class="remove-filter" @click="removeFilter()">очистить фильтр</span>

                </div>
            </div>
            <div class="table" v-if="this.getFilteredEmployees.length && this.allEmployees">
                <table>
                    <tr>
                        <th>
                            <div style="border-bottom-left-radius: 15px;">№</div>
                        </th>
                        <th>ФИО</th>
                        <th>рейтинг</th>
                        <th>клиенты</th>
                        <th>город</th>

                    </tr>


                    <rowsTable v-for="(employee, index) in displayedEmployee" :key="employee.id" :employee='employee'
                        :rows="index + 1"></rowsTable>

                </table>


            </div>
            <p v-else class="no-result">Ничего не найдено</p>


            <Paginate v-if="this.getFilteredEmployees.length > this.employeePerPage" :page-range='2'
                :initial-page="currentPage" :page-count="totalPages" :click-handler="goToPage" :active-class="'nav-activ-a'"
                :break-view-text="'...'" :prev-text="'<'" :next-text="'>'" :container-class="'nav-cont'"
                :page-class="'nav-li'" :page-link-class="'nav-a'" :prev-link-class="'nav-a'" :prev-class="'nav-li'"
                :next-link-class="'nav-a'" :next-class="'nav-li'">

            </Paginate>



        </div>
    </div>
</template>

<style scoped>
.filter {
    text-align: left;
    border-radius: 10px;
    font-size: 18px;
    margin: 20px 0px;
    padding: 5px;
}

.filter__btn {
    /* display: inline-block; */
    display: flex;
    max-width: 50%;
    justify-content: space-between;
    background: var(--mongo);
    padding: 5px;
    border-radius: 13px;
    margin-right: 20px;
    grid-gap:5px;
    align-items: center;
}


.company-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.company-info>div {
    min-width: 48.5%;
}

.filter__btn {
    display: inline-block;
    display: flex;
    max-width: 370px;
    width: 100%;
    justify-content: space-between;
    background: var(--orange);
    padding: 7px;
    border-radius: 10px;
    flex-wrap: wrap;
    grid-gap: 5px
}

.title {
    padding-top: 5px;
}

.costum-select {
    margin-bottom: 0;
    width: 200px;
    height: 30px;
}

.costum-select select {
    font-size: 15px;
    height: 30px;
}
</style>