<script>
import axios from "@/roleGuard"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { baseURL } from "@/config";
import { passwordIsValid, createNewFormData } from '@/utils'

import vFlags from "../../v-flags.vue"
import { generate_password } from '@/utils'; // Импортируйте функцию
import vNavigationPanel from "@/components/v-navigation-panel.vue";
import { vDialog, vBtn } from 'vuetify'

export default {
    name: "createClient",
    components: {
        vFlags, vNavigationPanel,
        vDialog, vBtn
    },
    data() {
        return {
            title: "Создание клиента",
            client: {},
            
            isPasswordValid: true,

        }
    },
    computed: {
        ...mapGetters('main',['getUser']),
        ...mapGetters('request', ['getLoading', 'getModal']),
    },
    methods: {
        ...mapActions('main',['GET_USER_INFO']),
        ...mapMutations("request", ['setResponse', 'setLoading', 'setModal']),

        handleInputEmployee(event) {
            const id = event.target.value
            const lawyer = this.allEmployees.find(obj => obj.id == id)
            const city = lawyer.city
            this.client.city = city
            this.client.lawyer = lawyer.id
        },
        close(){
            if(window.history.length > 2){
                this.$router.go(-1)
            }
            else{
                this.$router.push("/")
            }
        },
        generateNewPassword() {
            const password = generate_password();
            const input = this.$refs.password_input;
            input.value = password;
            this.client.password = password;
        },
        handleFileUpload(event) {
            this.client.file_pdf_contract = event.target.files[0];
        },
        handlePasswordInput() {
            this.isPasswordValid = true
        },
        handleEmailInput() {
            this.isEmailValid = true
        },
        createClient() {
            if (!passwordIsValid(this.client.password) && this.client.password) {
                this.setModal(false)
                this.setResponse({ status: false, message: 'Слишком простой пароль' })
                return
            }
            this.setLoading(true)
            this.client.lawyer = this.getUser.profile.id
            this.client.city = this.getUser.profile.city
            const url = baseURL + "client/create";
            const formData = createNewFormData(this.client, [])
            axios.post(url, formData)
                .then((response) => {
                    this.setResponse({ status: true, message: response.data.message })
                    this.client = {}
                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                    this.setModal(false)
                    const city = this.client.city
                    const lawyer = this.client.lawyaer
                    this.client.city = city
                    this.client.lawyer = lawyer
                })


        },
        checkMinLength() {
            const inputValue = this.client.number_phone;
            const inputElement = this.$refs.phoneInput;

            if (inputValue.length < 18) {
                inputElement.setCustomValidity('Введите номер телефона полностью.');
            } else {
                inputElement.setCustomValidity('');
            }

            inputElement.reportValidity(); // Для показа сообщения об ошибке валидации
        }
    },
    mounted() {
        this.GET_USER_INFO()
    }


}

</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel ></vNavigationPanel>
        <div>
          

            <form action="" class="content" @submit.prevent="setModal(true)">
                <div class="content-left">
                    <div class="input_group">
                        <p class="lk_input_p">ФИО</p>
                        <input required v-model="client.name" type="text" class="lk_input" placeholder="Введите ФИО"
                        pattern="[А-Яа-яA-Za-z\s]+" >
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">E-mail</p>
                        <input @input="handleEmailInput" required v-model="client.email" type="email" class="lk_input"
                            placeholder="E-mail">
                    </div>
                    <div class="input_group">
                        <p class="lk_input_p">Номер Договора</p>
                        <input required v-model="client.number_contract" type="text" class="lk_input" placeholder="№ 12">
                    </div>
                    <div class="input_group" style="text-align:left">
                        <p class="lk_input_p">Дата заключения договора</p>
                        <input required v-model="client.date_start_contract" type="date" max='2050-01-01' min='1900-01-01'
                            class="lk_input">

                    </div>
                    <!-- <div class="input_group" style="text-align:left; margin:10px 0">
                        <p class="lk_input_p">Прикрепить файл договора</p>
                        <input required class="input-file" style="padding-left:5px;" type="file" id="pdf" name="file"
                            placeholder="Файл не выбран" accept=".doc, .xlsx, .xls, .pdf, .docx" @change="handleFileUpload"
                            ref="fileInput">
                    </div> -->

                    <div class="input_group" style="text-align:left; margin:10px 0">
                        <p class="lk_input_p">Прикрепить файл договора</p>
                        <label for="pdf" class="custom-file-upload">

                            <span v-if="client.file_pdf_contract">{{ client.file_pdf_contract.name }}</span>
                            <span v-else>Выбрать файл</span>

                            <input required class="input-file" style="display: none;" type="file" id="pdf" name="file"
                                accept=".doc, .xlsx, .pdf, .xls, .docx" @change="handleFileUpload" ref="fileInput">

                        </label>
                    </div>


                    <div class="input_group">
                        <p class="lk_input_p">Выбрать специалиста</p>
                        <div class="costum-select">
                            <select class = "disabled" disabled>
                                <option selected :value="this.getUser.profile.id">{{this.getUser.profile.name}}</option>
                            </select>
                        </div>
                    </div>
                   
                    <div class="input_group">
                        <p class="lk_input_p">Город обращения</p>
                        <div class="costum-select">
                            <select class = "disabled" disabled>
                                <option selected :value="this.getUser.profile.city">{{getUser.profile.city}}</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div class="content-right">
                    <div class="input_group">
                        <p class="lk_input_p">Дата рождения</p>
                        <input required v-model="client.date_of_birth" type="date" max='2050-01-01' min='1900-01-01'
                            class="lk_input">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Номер телефона</p>
                        <input @input="checkMinLength" ref="phoneInput" required v-model="client.number_phone" type="text"
                            class="lk_input " v-phone placeholder="+79819191251">
                    </div>


                    <div class="input_group">
                        <p class="lk_input_p" @click="generateNewPassword()">Пароль <span style="float:right;" href=""
                                class="bottom_links">сгенеририровать
                                новый</span></p>
                        <input @input="handlePasswordInput" required v-model="client.password" type="text"
                            ref="password_input" class="lk_input" placeholder="Введите пароль">
                    </div>



                    <div class="input_group">
                        <p class="lk_input_p">Сумма по договору</p>
                        <input required v-model="client.contract_amount" type="number" class="lk_input"
                            placeholder="Введите сумму по договору">
                    </div>

                    <div class="input_group" style="text-align: left;">
                        <p class="lk_input_p">Оплачено по договору</p>
                        <input required v-model="client.paid" :max="client.contract_amount" type="number" class="lk_input"
                            placeholder="Введите оплаченную сумму">

                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Вопрос обращения</p>
                        <textarea required type="text" class="lk_input"
                            style="height:140px;align-items: top;padding-top:20px;" placeholder="Введите вопрос обращения"
                            v-model="client.additionally" maxlength="150"></textarea>
                    </div>

                </div>
           

                <div class="input_group" style="margin:auto">
                    <button class="lk__btn">СОЗДАТЬ КЛИЕНТА</button>
                    <p class="bottom_links" @click="close()">отменить создание и выйти</p>
                </div>

            </form>


            <v-dialog width="300px" v-model="getModal" @click:outside="setModal(false)">
                <template v-slot:default="{ }">
                    <div>
                        <form class="modal" @submit.prevent="createClient()">
                            <h2>Продолжить?</h2>
                            <div class="buttons">
                                <v-btn :loading="getLoading" type="submit"
                                    color="var(--green)"><span>Сохранить</span></v-btn>
                                <v-btn color="var(--red)" @click="setModal(false)"><span>Выйти</span></v-btn>
                            </div>
                        </form>
                    </div>
                </template>
            </v-dialog>


        </div>

    </div>
</template>

<style scoped>


.input-file {
    color: white;
    margin-top: 10px;
}

.lk__btn {
    margin-top: 13px;
    max-width: 350px;
}
.disabled{
    background: grey;
    cursor:not-allowed
}
</style>