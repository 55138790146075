<script>
import axios from "@/roleGuard"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { baseURL } from "@/config";
import { clearFilter, formatName } from '@/utils'

import vFlags from '@/components/v-flags.vue';
import rowsTable from './rows-table.vue';
import Paginate from 'vuejs-paginate-next'
import vNavigationPanel from "@/components/v-navigation-panel.vue";
import vSkeletonLoader from "vuetify"



export default {
    name: "adminRating",
    components: {
        vFlags,
        rowsTable, vSkeletonLoader,
        Paginate, vNavigationPanel
    },
    data() {
        return {
            title: "Рейтинг юристов",
            currentPage: 1,
            employeePerPage: 10,
            // Надо будет уудалить 
            filter: {
                city: '',
                count_clients: false,
                lowest_rating: true,
                total_sum_contract: false,
                obligations: false
            },

            table1: {}, //первая таблица с информацие внизу страницы
            table2: {}
            // companyInfoByCity: {}
        }
    },
    computed: {
        ...mapGetters('main', ['getCompanyInfo', 'allCities', 'allEmployees', 'getFilteredEmployees']),
        displayedEmployee() {
            const startIndex = (this.currentPage - 1) * this.employeePerPage;
            const endIndex = startIndex + this.employeePerPage;
            return this.getFilteredEmployees.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.getFilteredEmployees.length / this.employeePerPage);
        },

    },
    methods: {
        ...mapMutations('main', ['setFilteredEmployees']),
        ...mapActions('main', ['loadCities', 'GET_EPLOYEES_FROM_API']),

        formatNameComponent(name) {
            return formatName(name)
        },
        goToPage(pageNumber) {
            this.currentPage = pageNumber;
        },

        ratingFilter() {
            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredEmployees, ['rating']);
            this.setFilteredEmployees(sort.reverse())
            this.currentPage = 1
        },
        filterByCountClients() {
            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredEmployees, [function (user) { return user.his_clients.length }]);
            this.setFilteredEmployees(sort.reverse())
            this.currentPage = 1
        },
        filterBySum() {
            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredEmployees, ['total_sum_contract']);
            this.setFilteredEmployees(sort.reverse())
            this.currentPage = 1
        },
        filterByDolg() {
            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredEmployees, ['obligations']);
            this.setFilteredEmployees(sort.reverse())
            this.currentPage = 1
        },
        filterByCity() {
            const _ = require('lodash');
            const sort = _.filter(this.allEmployees,
                ['city', this.filter.city]);

            this.setFilteredEmployees(sort)
            this.currentPage = 1
        },
        removeFilter() {
            clearFilter(this.filter)
            this.filter.city = ""
            this.setFilteredEmployees(this.allEmployees)
            this.currentPage = 1
        },

        GET_INFO_COMPANY_FROM_API(city) {
            this.table1 = {}
            this.table2 = {}
            const url = baseURL + 'company/info'

            const params = {
                "city": city
            }
            axios.get(url, { params: params })
                .then((response) => {
                    // this.table1 = {}
                    // this.table2 = {}
                    // console.log(response)
                    this.table1 = response.data.table1
                    this.table2 = response.data.table2
                })
                .catch(() => {
                    this.table1 = {}
                    this.table2 = {}
                }
                )
        }

    },
    created() {
        this.loadCities()
        this.GET_EPLOYEES_FROM_API()
    },
    mounted() {
        this.GET_INFO_COMPANY_FROM_API('')
    },
    watch: {
        'filter.city': {
            handler(newValue) {
                this.GET_INFO_COMPANY_FROM_API(newValue)
            },
            deep: true,
        }
    }
}

</script>

<template>
    <div class="lk-page">
        <div class="rating-yurist">
            <vFlags :text_flags="title"></vFlags>
            <vNavigationPanel></vNavigationPanel>

            <div class="filter">
                <p>фильтр рейтинга:</p>

                <div style="margin:10px 0">
                    <div>
                        <div class="filter__btn">
                            <span class="title">выбрать город</span>
                            <div class="costum-select">
                                <select name="" id="" class="" v-model='filter.city' @change="filterByCity">
                                    <option disabled value="">все</option>
                                    <option v-for="city in allCities" :key="city.id" :value="city.city">{{ city.city }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.lowest_rating"
                            @change="ratingFilter()">
                        <label for="ch1">рейтинг</label>
                    </span>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox " v-model="filter.count_clients"
                            @change="filterByCountClients()">
                        <label for="ch1">клиенты</label>
                    </span>
                    <span>
                        <input type="checkbox" class="checkbox" v-model="filter.total_sum_contract" @change="filterBySum()">
                        <label for="ch1">сумма</label></span>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.obligations"
                            @change="filterByDolg">
                        <label for="ch1">долг</label>
                    </span>

                    <span class="remove-filter" @click="removeFilter()">очистить фильтр</span>
                </div>



            </div>
            <p v-if="!this.getFilteredEmployees.length" class="no-result">Ничего не найдено</p>


            <div class="table" v-if="this.getFilteredEmployees.length && this.allEmployees">
                <table class="tables_with_info">
                    <tr>
                        <th>
                            <div style="border-bottom-left-radius: 15px;">№</div>
                        </th>
                        <th>ФИО</th>
                        <th>рейтинг</th>
                        <th>клиенты</th>
                        <th>город</th>
                        <th>сумма</th>
                        <th>долг</th>
                    </tr>


                    <rowsTable v-for="(employee, index) in displayedEmployee" :key="employee.id" :employee='employee'
                        :rows="index + 1"></rowsTable>

                </table>

            </div>
            <Paginate v-if="this.getFilteredEmployees.length > this.employeePerPage" :page-range='2'
                :initial-page="currentPage" :page-count="totalPages" :click-handler="goToPage" :active-class="'nav-activ-a'"
                :break-view-text="'...'" :prev-text="'<'" :next-text="'>'" :container-class="'nav-cont'"
                :page-class="'nav-li'" :page-link-class="'nav-a'" :prev-link-class="'nav-a'" :prev-class="'nav-li'"
                :next-link-class="'nav-a'" :next-class="'nav-li'">

            </Paginate>

            <div class="info content" style="text-align:left; ">
                <div class="content-left ">
                    <table class="tables_with_info">
                        <tr>
                            <th>Статистика:</th>
                        </tr>

                        <tr>
                            <td>
                                <span>Юристов:</span>
                                <v-skeleton-loader  v-if="table1.count_employee == undenfined" class="sc" type="list-item"></v-skeleton-loader>
                                <span v-else>{{ table1.count_employee }}</span>
                              
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <span>Рейтинг:</span>
                                <v-skeleton-loader  v-if="table1.avg_rating_company == undenfined" class="sc" type="list-item"></v-skeleton-loader>
                                <span v-else>{{ table1.avg_rating_company }}</span>
                               
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Клиентов:</span>
                                <v-skeleton-loader  v-if="table1.count_client == undenfined" class="sc" type="list-item"></v-skeleton-loader>
                                <span v-else>{{ table1.count_client }}</span>
                               
                            </td>
                        </tr>
                        <tr>

                            <td>
                                <span>Сумма:</span>
                                <v-skeleton-loader  v-if="table1.summ_contract == undenfined" class="sc" type="list-item"></v-skeleton-loader>
                                <span v-else >{{ table1.summ_contract }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td style="border-bottom: 0;">
                                <span>Долг:</span>
                                
                                <v-skeleton-loader v-if="table1.obligation == undenfined" class="sc" type="list-item"></v-skeleton-loader>
                                <span  v-else>{{ table1.obligation }}</span>
                            </td>

                        </tr>
                    </table>
                </div>

                <div class="content-right">
                    <table class="tables_with_info">
                        <tr>
                            <th>Информация:</th>
                        </tr>

                        <tr>
                            <td>
                                <span>Отправлено сообщений:</span>
                                <v-skeleton-loader width = "45%" v-if="table2.count_messages == undenfined"  class="sc" type="list-item"></v-skeleton-loader>
                                <span v-else >{{ table2.count_messages }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Новый клиент:</span>
                                <v-skeleton-loader width = "55%" v-if="!table2.last_client" class="sc"
                                    type="list-item"></v-skeleton-loader>
                                <span v-else-if="!table2.last_client.name">Информация не найдена</span>

                                <router-link v-else
                                    :to="{ name: 'watchClient', params: { clientId: table2.last_client.id } }">
                                    {{ formatNameComponent(table2.last_client.name) }}
                                </router-link>
                            </td>
                        </tr>
                        <tr>


                            <td>
                                <span>Высший рейтинг:</span>
                                <v-skeleton-loader width = "55%" v-if="!table2.employee_highest_rating" class="sc"
                                    type="list-item"></v-skeleton-loader>
                                <span v-else-if="!table2.employee_highest_rating.name">Информация не найдена</span>

                                <router-link v-else
                                    :to="{ name: 'yuristProfile', params: { employeeId: table2.employee_highest_rating.id } }">
                                    {{ formatNameComponent(table2.employee_highest_rating.name) }}
                                </router-link>
                            </td>

                        </tr>
                        <tr>

                            <td style="border-bottom: 0;">
                                <span>Низший рейтинг:</span>
                                <v-skeleton-loader width = "55%" v-if="!table2.employee_lowest_rating" class="sc"
                                    type="list-item"></v-skeleton-loader>
                                <span v-else-if="!table2.employee_lowest_rating.name">Информация не найдена</span>

                                <router-link v-else
                                    :to="{ name: 'yuristProfile', params: { employeeId: table2.employee_lowest_rating.id } }">
                                    {{ formatNameComponent(table2.employee_lowest_rating.name) }}
                                </router-link>
                            </td>
                        </tr>


                    </table>
                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>
.filter {
    text-align: left;
    border-radius: 10px;
    font-size: 18px;
    margin: 20px 0px;
    padding: 5px;
}


.info>div {
    min-width: 48.5%;
}

.info {
    display: flex;
    margin-top: 20px;

}

.flex {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
}

.filter__btn {
    /* display: inline-block; */
    display: flex;
    max-width: 370px;
    width: 100%;
    justify-content: space-between;
    background: var(--orange);
    padding: 5px;
    border-radius: 13px;
    flex-wrap: wrap;
    grid-gap: 5px;
    align-items: center;
}

.title {
    padding-top: 5px;
}
@media (max-width:480px) {
    .costum-select {
    /* margin-bottom: 0; */
    width: 100%;
    height: 35px;
}
}
@media (min-width:480px) {
    .costum-select {
    /* margin-bottom: 0; */
    width: 200px;
    height: 35px;
}


}

.costum-select select {
    font-size: 15px;
    height: 35px;
}

</style>