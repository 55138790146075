<script>
export default {
    name: "authMessage",
    props: {
        msg_text: String,
        status: Boolean,

    },
    methods: {
        check_response() {
            if (this.msg_text) {
                return true;
            }

            return false;
        }

    }
}

</script>

<template>
    <div class="auth-message" v-if="check_response()">
        <div :style="{ backgroundColor: status ? 'var(--green)' : 'var(--red)' }" class="message">
            <p>{{ msg_text }}</p>

        </div>
    </div>
</template>

<style
scoped
>
.auth-message {
    margin: 20px;
    font-family: regular;
    min-width: 300px;
}

.message {
    padding: 10px;
    border-radius: 12px;
    margin:20px 0;
}

.bottom_links {
    font-size: 20px;
 
}

.auth-message>p {
    font-size: 20px;
}</style>