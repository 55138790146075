<script>
import axios from "@/roleGuard"
import { baseURL } from "@/config"

import vFlags from "../../v-flags.vue"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { logout_from_profile } from '@/utils'
import vNavigationPanel from "@/components/v-navigation-panel.vue"
import vSkeletonLoader from "vuetify"

export default {
    name: "lkAdmin",
    components: {
        vFlags, vNavigationPanel, vSkeletonLoader

    },
    data() {
        return {
            title: "Забюрист",
            searchClient: '',
            searchEmployee: '',
            search_client_show: false,
            search_employee_show: false,
            client_filter: [],
            employee_filter: [],
            table1: {}

        }
    },
    computed: {
        ...mapGetters('main', ['allClients', 'allEmployees', 'getUser', 'getCompanyInfo']),


    },

    methods: {
        ...mapActions('main', ['GET_CLIENTS_FROM_API',
            'GET_EPLOYEES_FROM_API',
            'loadCities',
            'GET_USER_INFO',
        ]),
        ...mapMutations('main', ['setPrevPath']),
        logout() {
            logout_from_profile(this.$router)
        },

        clearInputClient() {
            this.client_filter = []; // Если поле ввода пусто, очистите результат
            this.search_client_show = false
            this.searchClient = ''
        },
        clearInputEmployee() {
            this.employee_filter = []; // Если поле ввода пусто, очистите результат
            this.search_employee_show = false
            this.searchEmployee = ''
        },
        filteredClients() {

            if (this.searchClient.trim() === '') {
                this.client_filter = []; // Если поле ввода пусто, очистите результат
                this.search_client_show = false
                return;
            }
            this.search_client_show = true
            // Фильтрация клиентов на основе введенного текста
            const filtered = this.allClients.filter(client =>
                client.name.toLowerCase().includes(this.searchClient.toLowerCase())
            );

            this.client_filter = filtered
        },
        filteredLawyers() {

            if (this.searchEmployee.trim() === '') {
                this.employee_filter = []; // Если поле ввода пусто, очистите результат
                this.search_employee_show = false
                return;
            }
            this.search_employee_show = true
            // Фильтрация клиентов на основе введенного текста
            const filtered = this.allEmployees.filter(employee =>
                employee.name.toLowerCase().includes(this.searchEmployee.toLowerCase())
            );

            this.employee_filter = filtered
        },

        GET_INFO_COMPANY_FROM_API() {
            this.table1 = {}

            const url = baseURL + 'company/info'

            const params = {
                "city": ''
            }
            axios.get(url, { params: params })
                .then((response) => {

                    this.table1 = response.data.table1

                })
                .catch(() => {
                    this.table1 = {}

                }
                )
        }
    },

    mounted() {
        this.GET_CLIENTS_FROM_API(),
            this.GET_EPLOYEES_FROM_API(),
            this.loadCities(),
            this.GET_INFO_COMPANY_FROM_API()
    },
}
</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel :showPrevLink=false></vNavigationPanel>
        <div class="content">
            <div class="content-left">


                <p class="lk_input_p">Быстрый поиск клиента</p>
                <input type="text" class="lk_input" v-model="searchClient" @input="filteredClients"
                    placeholder="Введите фио клиента...">
                <div class="result_search" v-if="this.search_client_show">
                    <p class="result-a" style="font-family: pro; " v-if="!client_filter.length" @click="clearInputClient()">
                        Очистить резльтаты поиска</p>
                    <router-link class="result-a" v-for="client in client_filter" :key="client.id"
                        :to="{ name: 'watchClient', params: { clientId: client.id } }">{{ client.name }}</router-link>
                </div>
                <p class="lk_input_p">Быстрый поиск юриста</p>
                <input type="text" class="lk_input" placeholder="Введите фио юриста..." v-model="searchEmployee"
                    @input="filteredLawyers">
                <div class="result_search" v-if="this.search_employee_show">
                    <p class="result-a" style="font-family: pro; " v-if="!employee_filter.length"
                        @click="clearInputEmployee()">Очистить резльтаты поиска</p>

                    <router-link class="result-a" v-for="employee in employee_filter" :key="employee.id"
                        :to="{ name: 'yuristProfile', params: { employeeId: employee.id } }">{{ employee.name
                        }}</router-link>
                </div>

                <ul class="lk_navigation">
                    <RouterLink :to="{ name: 'createClient' }" class="bottom_line">> Создать нового клиента</RouterLink>
                    <RouterLink :to="{ name: 'createYurist' }" class="bottom_line">> Создать нового юриста</RouterLink>
                    <router-link :to="{ name: 'clientAdminspisok' }" class="bottom_line">> Список всех
                        клиентов</router-link>
                    <RouterLink :to="{ name: 'adminRating' }" href="">> Рейтинг юристов компании</RouterLink>
                </ul>

            </div>

            <div class="content-right">
                <div class="content-right__info">
                    <ul class="content-right__ul">
                        <li class="flex-li">Имя: {{ getUser.first_name }}</li>
                        <li class="flex-li">E-mail: {{ getUser.email }}</li>
                        <li class="flex-li"><span>Клиентов в компании:</span>
                            <v-skeleton-loader width="25%" v-if="table1.count_client == undenfined" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else> {{ table1.count_client }}</span>
                        </li>
                        <li class="flex-li"> <span>Юристов в компании: </span>

                            <v-skeleton-loader width="25%" v-if="table1.count_employee == undenfined" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else> {{ table1.count_employee }}</span>
                        </li>
                        <li class="flex-li"><span> Заключено на сумму:</span>
                            <v-skeleton-loader width="25%" v-if="table1.summ_contract == undenfined" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else> {{ table1.summ_contract }}</span>
                        </li>


                        <li class="flex-li"><span>Должники:</span>
                            <v-skeleton-loader width="25%" v-if="table1.obligation == undenfined" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else> {{ table1.obligation }}</span>

                        </li>
                        <li class="flex-li"><span>Средний рейтинг компании:</span>
                            <v-skeleton-loader width="25%" v-if="table1.avg_rating_company == undenfined" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else> {{ table1.avg_rating_company }}</span>
                        </li>
                    </ul>
                </div>
                <p>
                    <RouterLink to="/admin/change" href="" class="bottom_links">редактировать данные</RouterLink>
                </p>
                <p href="" @click="logout()" class="bottom_links" style="color:var(--yellow)">выйти из кабинета</p>
            </div>
        </div>
    </div>
</template>

<style>
.result_search {

    text-align: left;
    padding: 10px;
    color: var(--blue);
    background: white;
    margin-top: 10px;
    border-radius: 12px;
    max-height: 200px;
    min-height: 40px;
    width: 100%;
    max-width: 450px;
    overflow: auto;
    margin:0 auto;
    margin-top: 10px;
}


.result-a {
    display: block;
    color: var(--blue);
    padding-left: 5px;
    cursor: pointer;
}

.flex-li{
    display: flex;
    grid-gap:10px;
}
/*right*/
</style>