<script>
import { baseURL } from "@/config"
import axios from "@/roleGuard"
import { createNewFormData } from "@/utils"
import vBtn from "vuetify"

import { mapMutations } from "vuex"
export default {
    emits: ['messageIsChanged'],
    name: "changeMessage",
    components: {
        vBtn
    },
    props: {
        message: Object
    },
    data() {
        return {
            newMessage: {
                text: this.message.text,
                file: '',
            },
            fileName: '',
            created: {
                status: false
            },
            loading: false
        }
    },
    computed: {
        fileExists() {

            if (this.fileName) {
                return true
            }
            return false
        }
    },

    methods: {
        ...mapMutations('main', ["updateMessage"]),
        ...mapMutations("request", ['setResponse']),
        handleFileUpload(event) {
            const file = event.target.files[0]
            this.newMessage.file = file;
            if (file) {
                this.fileName = file.name
            }
            else {
                this.fileName = ''
            }
        },
        clearFile() {
            this.newMessage.file = ''
            this.fileName = ''
        },
        //сделать функцию отменить редактирование
        changeMessageInDB() {
            this.loading = true
            const messageId = this.message.id
            const url = baseURL + `messages/${messageId}/update`
            const headers = {
                'Content-Type': 'multipart/form-data'
            }
            if (!this.fileName) {
                this.newMessage.file = "del"
            }
            const formData = createNewFormData(this.newMessage, [])
            axios.put(url, formData, { headers: headers })
                .then((response) => {
                    this.updateMessage(response.data)
                    this.setResponse({ status: true, message: "Отредактировано" })
                    this.$emit('messageIsChanged', true)
                })
                .catch(() => this.$emit('messageIsChanged', false))
                .finally(() => {
                    this.loading = false
                })
        }
    },
    created() {
        this.fileName = this.message.file
    }


}
</script>

<template>
    <div class="change-message">
        <form action="" @submit.prevent="changeMessageInDB()">
            <div style="position: relative;">
                <textarea rows="7" v-model="this.newMessage.text" maxlength="200" required
                    placeholder="Введите текст сообщения...">
                </textarea>

                <div class="icon-cont">

                    <input type="file" name="file" @change="handleFileUpload" placeholder="Файл не выбран" id="file"
                        accept=".doc,.docx, .xlsx, .xls, .pdf " ref="fileInput"> <!-- -->
                    <label for="file" class="input-label">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"> </i>
                    </label>

                    <i v-if="fileExists" class="fa fa-times-circle" @click="clearFile()" aria-hidden="true"></i>
                </div>
            </div>


            <label for="file" class="input-label">
                <p class="input__file-text">
                    {{ fileName }}</p>
            </label>
            <v-btn :loading = "loading" type="submit" class="button" variant="text">РЕДАКТИРОВАТЬ СООБЩЕНИЕ</v-btn>

        </form>
    </div>
</template>

<style scoped>
.change-message {
    width: 100%;
    background: var(--blue);
    padding: 10px;
    border-radius: 11px;
    margin: 15px 0;

}

textarea {
    outline: none;
    font-size: 18px;
    width: 100%;
    padding: 10px;
    border-radius: 11px;
    margin-bottom: 5px;
}

#file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}



.fa {
    color: black;
    margin-left: 10px;
    cursor: pointer;
}

.icon-cont {

    position: absolute;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.input__file-text {
    font-size: 10px;
    color: rgba(240, 248, 255, 0.941);
    padding-left: 4px;
}

.input-label {
    text-align: left;
    cursor: pointer;
}

.button {
    font-size: 15px;
    font-weight: 600;
    font-family: pro;
    color: white;
    cursor: pointer;
    border: 0;
    background: 0;
    width:100% ;
}
</style>