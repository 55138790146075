<template>
    <div class="v-navigation-panel">
        <span v-if="showPrevLink" class="first-link">
            <span v-if="prevTitle" @click="this.$router.go(-1)" class="hov bottom_links"> {{ prevTitle }}</span>
            <span v-else @click="this.$router.push('/')" class="hov bottom_links"> {{ isAdminUser }}</span>
        </span>
        
        <router-link :to="currentPage" class="hov"> {{ currentTitle }}</router-link>
    </div>
</template> 
 

<script>
import { allRoutes } from "@/router"
import { isAdmin } from "@/utils"
export default {
    name: 'vNavigationPanel',

    props: {
        showPrevLink: { type: Boolean, default: true },
    },

    computed: {
        isAdminUser(){
            if(isAdmin()){
                return 'Линчый кабинет админа'
            }
            return 'Личный кабинет юриста'
           },
        currentPage() {
            const route = window.history.state.current
            return route
        },
        currentTitle() {
            const title = this.$route.meta.title
            return title
        },
        prevPage() {
            return window.history.state.back
        },
        prevTitle() {
            try {
                const regex = /\/employee\/\d+\/clients\//;
                if (regex.test(this.prevPage)) {
                    return "Список клиентов"
                }
                const previous = this.delNumber(this.prevPage)

                const index = allRoutes.findIndex(route => this.delParametr(route.path) === previous);
                if (index === -1) {
                    return
                }
                const obj = allRoutes[index]
                const title = obj.meta.title

                return title
            }
            catch {
                return ''
            }
        }

    },
    methods: {
        delParametr(route) {
            const routeWithoutParams = route.replace(/\/:[^/]+\/?$/, '');
            return routeWithoutParams
        },
        delNumber(route) {
            const routeWithoutNumber = route.replace(/\/\d+\/?$/, '');
            return routeWithoutNumber
        },
    }

    }
</script>

<style scoped>
.v-navigation-panel {
    text-align: left;
    width: 100%;
}

.first-link::after {
    margin: 0 8px 0 13px;
    text-decoration: none;
    content: '\e048';
    font-family: typeicons;
}

.bottom_links {
    font-size: 18px;
}

.v-navigation-panel>span {
    display: inline-block;
    font-size: 18px;
    padding: 0 5px;
    font-size: 18px;
}

.hov:hover {
    color: var(--orange);
    transition: color 0.2s;
}
</style>