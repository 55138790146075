import axios from 'axios'
import { baseURL } from './config';
import store from "@/store"

async function checkValidToken(token) {
  const url = baseURL + "user/info";
  try {
    const response = await axios.get(url, { headers: { "Authorization": `Token ${token}` } });
    store.state['main'].user_data = response.data
    return true;
    
  } catch (error) {
    console.error("Token is invalid");
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    return false;
  }
}

export const checkRoleAndTokenGuard = async (to, from, next) => {
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  // Если есть роль и токен, перенаправляем на соответствующий маршрут

  if (role && token && await checkValidToken(token)){ /// 
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    // проверим действительный ли токен, если вдруг он есть
    if (role === 'admin') {
      next({ name: 'lkAdmin' });
    } else if (role === 'employee') {
      next({ name: 'lkYurist' });
    } else {
      next({ name: 'lkClient' });
    }
  } else if (to.name !== 'authForm') { // Проверяем, что это не страница аутентификации
    // Если нет роли или токена, перенаправляем на страницу входа
    next({ name: 'authForm' });
  } else {
    next();
  }
};

export const adminAndEmployeeGuard = async (to, from, next) => {
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  if (token && role && await checkValidToken(token)) {
    if (role === 'admin' || role ==='employee') {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      next() // Разрешаем доступ администраторам
    } 
    else{
      next('/')
    }
  }else {
    next('/'); // Запрещаем доступ другим пользователям
  }
};

// // Гвард для администраторов
export const adminGuard = async (to, from, next) => {
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  if (token && role && await checkValidToken(token)) {
    if (role === 'admin') {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      next() // Разрешаем доступ администраторам
    } 
    else{
      next('/')
    }
  }else {
   next('/') // Запрещаем доступ другим пользователям
  }

};

// Гвард для юристов
export const yuristGuard = async (to, from, next) => {
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  if (token && role && await checkValidToken(token)) {
    if (role === 'employee') {
      axios.defaults.headers.common['Authorization'] = `Token ${token}`;
      next(); // Разрешаем доступ юристам
    } 
    else{
      next('/')
    }
  }else {
    next('/access-denied'); // Запрещаем доступ другим пользователям
  }
};

// Гвард для клиентов
export const clientGuard = async (to, from, next) => {
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token');
  if (token && role && await checkValidToken(token)) {
    axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    if (role === 'client') {
      next(); 
    } 
    else{
      next('/')
    }
  }
  else {
    next('/'); // Запрещаем доступ другим пользователям
  }
};


export default axios;