<script>
export default {
    name: "mainSidebar",
}
</script>

<template>
    <div class="sidebar">
        <div class="main-lft">
            <div class="main-lft-fixed">
                <img alt="ZABYURIST.RU - Юридические услуги онлайн" class="logo" itemprop="contentUrl"
                    src="@/assets/img/logo2.png" width="100%" />
                <!-- NAVIGATOR -->
                <div class="navigator">
                    <div class="nav-buy">
                        
                        <a  @click ="this.$router.push('/authForm')" class="nav-buy_lk-button" title="lk">ЛИЧНЫЙ КАБИНЕТ</a>
                        <a class="nav-buy_main-page" href="https://zabyurist.ru/">главная страница</a>
                    </div>
                    <div class="nav">
                        <ul>
                            <li><a target = '_blank' href="https://zabyurist.ru/uslugi">Юридические услуги</a></li>
                            <li><a target = '_blank' href="https://zabyurist.ru/business">Услуги для бизнеса</a></li>
                            <li><a  target = '_blank' href="https://zabyurist.ru/company">О нашей компании</a></li>
                            <li><a target = '_blank' href="https://zabyurist.ru/credit">Рассрочка на услуги</a></li>
                            <li><a target = '_blank' href="https://zabyurist.ru/work">Наши сотрудники</a></li>
                            <li class="navwin"><a href="https://zabyurist.ru/pobedy">ПОБЕДЫ ЗАБЮРИСТ</a></li>
                        </ul>
                    </div>
                </div>
                <!-- NAVIGATOR -->
                <div class="new_style">


                    <div class="ya-share2 ya-share2_inited" data-services="vkontakte,odnoklassniki,twitter"
                        data-counter="ig" style="margin: 10px 0; text-align: center;">
                        <div
                            class="ya-share2__container ya-share2__container_size_m ya-share2__container_color-scheme_normal ya-share2__container_shape_normal">
                            <ul class=" new_style-ul">
                                <li class="ya-share2__item ya-share2__item_service_vkontakte"><a class="ya-share2__link"
                                        href="https://vk.com/share.php?url=https%3A%2F%2Fzabyurist.ru%2Fuslugi&amp;title=%D0%AE%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&amp;utm_source=share2"
                                        rel="nofollow noopener" target="_blank" title="ВКонтакте">
                                        <span class="ya-share2__badge">
                                            <span class="ya-share2__icon"></span></span></a></li>
                                <li class="ya-share2__item ya-share2__item_service_odnoklassniki"><a class="ya-share2__link"
                                        href="https://connect.ok.ru/offer?url=https%3A%2F%2Fzabyurist.ru%2Fuslugi&amp;title=%D0%AE%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&amp;utm_source=share2"
                                        rel="nofollow noopener" target="_blank" title="Одноклассники"><span
                                            class="ya-share2__badge"><span class="ya-share2__icon"></span></span></a></li>
                                <li class="ya-share2__item ya-share2__item_service_twitter"><a class="ya-share2__link"
                                        href="https://twitter.com/intent/tweet?text=%D0%AE%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&amp;url=https%3A%2F%2Fzabyurist.ru%2Fuslugi&amp;utm_source=share2"
                                        rel="nofollow noopener" target="_blank" title="Twitter"><span
                                            class="ya-share2__badge"><span class="ya-share2__icon"></span></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="ya-rait"><iframe frameborder="0" height="50"
                        src="https://yandex.ru/sprav/widget/rating-badge/1386949173" width="150"></iframe></div>
            </div>
        </div>

    </div>
</template>

<style scoped>
.new_style {
    max-width: 80px;
    height: 26px;
    margin: auto auto;
    margin-bottom: 5px;
}

.new_style-ul {
    display: flex;
    width: 80px;
    justify-content: space-between;
}

.ya-share2__container_size_m {
    font-size: 13px;
}


.ya-share2__link {
    text-decoration: none;
    white-space: nowrap;
}

.ya-share2__link {
    display: block;
}


.ya-share2__list,
.ya-share2__badge,
.ya-share2__icon {
    display: inline-block;
    vertical-align: top;
}

.sidebar {
    font-family: regular;
    font-size: 20px;
    color: #2e4966;
    text-align: center;
    max-width: 325px;
    width: 100%;

}

.sidebar a {
    text-decoration: none;
    display: block;
}

.nav-buy,
.navwin {
    background-image: url("@/assets/img/vip-bg.png");
}

.main-lft {
    display: table-cell;
    vertical-align: top;
    padding: 25px 25px 0 0;
    width:100%
}

.nav-buy_main-page {
    color: white;
    font-family: regular;
    background: var(--dark);
    border-radius: 9px;
    margin-top: 10px;
    font-size: 20px;
    padding: 2px 0;
    width: 100%;
}

.ya-rait {
    margin: 0 auto;
    width: 150px;
}

.main-lft,
.main-rgt,

.main-lft,
.main-rgt,
table.product td {
    vertical-align: top;
}

img {
    width: 100%;
}

.navigator {
    font-size: 20px;
    border-radius: 11px;
    margin-top: 17px;
    background: var(--blue);
}


.nav {
    color: white;
    text-align: left;
    background: #2e2727;
    padding: 10px;
    border-radius: 11px;
}

.nav-buy {
    border-radius: 11px 11px 0 0;
    padding: 10px;
    text-align: center;
}

.nav-buy .button {
    width: 100%;
}


.nav a {
    display: block;
    font-family: regular;
    font-size: 20px;
    display: block;
    padding: 7px 10px;
    color: #aaa;
    border-bottom: 5px solid #251f1f;
    text-decoration: none;
}

.nav-buy span {
    width: 100%;
    margin: 0 0 10px;
    display: block;
    color: #fff;
    font-family: pro;
    font-size: 16px !important;
    margin-bottom: 10px;
    text-shadow: 0 1px 0 rgb(0 0 0 / 20%);
}

.nav-buy_lk-button {
    color: white;
    white-space: nowrap;
    background-color: #e63631;
    font-size: 22px;
    padding: 10px;
    width: 100%;
    border: 0;
    border-radius: 11px;
    box-shadow: inset 0 -4px 0 0 rgb(0 0 0 / 20%);
    font-family: pro;
    cursor: pointer;
}

.nav-buy_lk-button:hover {
    background: var(--light-blue)
}


.nav a:hover,
.navok a {
    background-color: #ff9200;
    color: #fff;
}



.nav li {
    list-style: none;
}

ul {
    padding: 0
}

.navwin a {
    text-align: center;
    background-color: #e52424;
    color: #fff;
    font-family: pro;
}

.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
}

.ya-share2__item_service_odnoklassniki .ya-share2__badge,
.ya-share2__item_service_odnoklassniki .ya-share2__mobile-popup-badge {
    background-color: #f70;
}


.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
}

.ya-share2__list.ya-share2__list_direction_horizontal>.ya-share2__item {
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 2px 4px 0 0;
}

.ya-share2__item_service_vkontakte .ya-share2__badge,
.ya-share2__item_service_vkontakte .ya-share2__mobile-popup-badge {
    background-color: #07f;
}

.ya-share2__item_service_twitter .ya-share2__badge,
.ya-share2__item_service_twitter .ya-share2__mobile-popup-badge {
    background-color: #0f1419
;
}

.ya-share2__item_service_vkontakte .ya-share2__icon {
    background-image: url(data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.878 17.304c-5.411 0-8.695-3.755-8.823-9.994h2.74c.086 4.583 2.171 6.528 3.77 6.925V7.31h2.627v3.954c1.542-.17 3.155-1.97 3.698-3.954h2.584c-.414 2.441-2.17 4.24-3.412 4.983 1.242.6 3.24 2.17 4.011 5.01h-2.84c-.6-1.898-2.07-3.369-4.04-3.569v3.57h-.315Z' fill='%23fff'/%3E%3C/svg%3E);
}

.ya-share2__badge {
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    position: relative;
}

.ya-share2__item_service_twitter .ya-share2__icon {
    background-image: url(data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 7.539a6.56 6.56 0 01-1.885.517 3.294 3.294 0 001.443-1.816 6.575 6.575 0 01-2.085.796 3.283 3.283 0 00-5.593 2.994A9.32 9.32 0 015.114 6.6a3.28 3.28 0 001.016 4.382 3.274 3.274 0 01-1.487-.41v.041a3.285 3.285 0 002.633 3.218 3.305 3.305 0 01-1.482.056 3.286 3.286 0 003.066 2.28A6.585 6.585 0 014 17.524 9.291 9.291 0 009.032 19c6.038 0 9.34-5 9.34-9.337 0-.143-.004-.285-.01-.425A6.672 6.672 0 0020 7.538z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E);
}

.ya-share2__list,
.ya-share2__badge,
.ya-share2__icon {
    display: inline-block;
    vertical-align: top;
}


.ya-share2,
.ya-share2 * {
    line-height: normal;
}

.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
}

.ya-share2__item_service_odnoklassniki .ya-share2__icon {
    background-image: url(data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.83 10.77a3.951 3.951 0 0 1-5.604 0 3.951 3.951 0 0 1 0-5.604 3.95 3.95 0 0 1 5.604 0 3.951 3.951 0 0 1 0 5.604Zm-2.802-4.615c-.494 0-.942.2-1.271.53a1.79 1.79 0 0 0 0 2.542 1.789 1.789 0 0 0 2.543 0 1.789 1.789 0 0 0 0-2.543 1.839 1.839 0 0 0-1.272-.53Zm4.168 5.792 1.166 1.59c.059.082.047.188-.036.247-.977.8-2.119 1.33-3.308 1.613l2.249 4.332c.059.13-.024.271-.165.271H13.7a.206.206 0 0 1-.176-.118l-1.496-3.579-1.507 3.567a.181.181 0 0 1-.176.118H7.943c-.141 0-.224-.153-.165-.27l2.249-4.321a8.262 8.262 0 0 1-3.308-1.613c-.071-.06-.095-.177-.036-.248l1.166-1.589c.07-.094.188-.106.27-.035 1.096.93 2.45 1.542 3.898 1.542s2.79-.6 3.897-1.542c.094-.07.223-.06.282.035Z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E);

    /* content: '\e0f9';
      font-family: 'typeicons';
      
    font-style: normal;
    font-weight: normal; */
}</style>