<script>
import authMessage from './auth-message.vue';
import axios from 'axios'
import { baseURL } from '@/config';
export default {
    name: "authRestore",
    components: {
        authMessage
    },
    data() {
        return {
            response: "",
            status: "",
            email: ''
        }
    },
    methods: {
        restore() {
            /* проверим есть ли такой пользователь 
             если есть напишем сообщение об отправке нового пароля
            */
            const url = baseURL + 'auth/restore'
            this.$refs.btn.setAttribute("disabled",'')
            this.$refs.btn.innerText = "ЗАПРОС ОТПРАВЛЕН"
            axios.post(
                url, {email: this.email}
            )
                .then(
                    () => {
                        this.response = "Письмо c новыми данным отправлено Вам на указанную почту."
                        this.status = true
                    }
                )
                .catch(() => {
                    this.response = "Пользователя с таким email не существует."
                    this.status = false
                    this.$refs.btn.innerText = "ВОСТАНОВИТЬ ДОСТУП"
                    this.$refs.btn.removeAttribute("disabled",'')
                })

        }
    }
}
</script>

<template>
    <div class="auth-restore">
     
        <p class="clue"><b style="font-weight: 800;">Введите свой E-mail:</b> Вам придет новый пароль на почту</p>
        <form action="" class="auth-form__form" @submit.prevent="restore()">
            <p class="input_text">Введите E-mail</p>
            <input type="text" class="auth-form__inp" placeholder="E-mail" v-model="email" required>
            <button class="lk__btn" ref = "btn" >
                ВОССТАНОВИТЬ ДОСТУП
            </button>
        </form>
        <authMessage :msg_text="response" :status="status"></authMessage>
        <RouterLink to="/authForm" class="bottom_links">вернуться на главную страницу</RouterLink>
    </div>
</template>

<style>
.auth-restore {
    text-align: center;
    margin: auto auto;
    color: white;
    font-family: regular;
}
</style>