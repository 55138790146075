<script>
import vFlags from "@/components/v-flags.vue"
import sendMessage from "@/components/send-message.vue"
import infoContainer from "@/components/info-container.vue"
import RatingUser from "@/components/rating-user.vue"
import changeMessage from "@/components/change-message.vue"
import axios from '@/roleGuard'
import { baseURL } from "@/config"
import { formatName, download, isAdmin, isEmployee, getLocalTime } from "@/utils"
import vNavigationPanel from "./v-navigation-panel.vue"

export default {
    name: "watchClient",
    components: {
        vFlags,
        sendMessage,
        infoContainer,
        RatingUser,
        changeMessage, vNavigationPanel
    },
    data() {
        return {
            title: "",
            title_button: "оценка клиента",
            client: {},

            show_send_message: true,
            show_change_message: false,
            message_for_change: Object,
            prevComponent: "",

        }
    },
    computed: {

        selectComponent() {
            if (isAdmin()) {

                return 'adminChangeclient'
            }

            return "yuristChangeclient"
        },
        getDateRating() {
            const date = getLocalTime(this.client.date_change_rating)

            return `${date[0]} в ${date[1]}` || "неизвестно"
        },

        allowSendMessage() {
            if (this.client.status_contract) { return false }
            return true
        }

    },
    methods: {
        isEmployeeUser() {
            if (isEmployee()) {
                return "lkYurist"
            }
            else if (isAdmin()) { return "yuristProfile" }
        },
        close() {
            this.$router.go(-2)
        },
        changeMessageText(message) {
            this.show_send_message = false,
                this.show_change_message = true,
                this.message_for_change = message
        },
        messageIsChanged() {
            this.show_send_message = true
            this.show_change_message = false

        },
        getDate(server_date) {
            return getLocalTime(server_date)[0]
        },

        getInfoClient(id) {
            const url = baseURL + `client/${id}`
            axios.get(url)
                .then(response => { this.client = response.data; })
                .catch(e => 
                {
                    const status = e.response.status
                    if (status==403){
                        this.$router.push('/access-denied')
                    }
                    else if(status == 404){
                        this.$router.push({name:'not-found'})
                    }
                
                })
        },
        formated(fullName) {
            return formatName(fullName)
        },
        downloadUserFile() {
            const id = this.client.user
            const url = baseURL + `user/${id}/download-file`
            download(url)
        },
        removeNonNumeric(inputString) {
            if (inputString) {
                return 'tel:' + inputString.replace(/[\s()-]/g, '');
            }
        },

    },
    created() {
        const id = this.$route.params.clientId
        this.getInfoClient(id)

    }
}
</script>

<template>
    <div class="lk-page" v-if="client.name">

        <vFlags :text_flags="formated(client.name)"></vFlags>
        <vNavigationPanel></vNavigationPanel>
        <div class="content">
            <div class="content-left">

                <div class="head-info">
                    <h2>Юрист:</h2>
                    <span v-if="client.lawyer">
                        <router-link :to="{ name: this.isEmployeeUser(), params: { employeeId: client.lawyer } }"
                            class="bottom_links lawyer" href>{{ client.lawyer_name }}</router-link>
                    </span>
                    <span v-else>Инофрмация не найдена</span>

                </div>

                <div style="position: relative;">

                    <div class="" v-if="allowSendMessage">
                        <sendMessage v-if="show_send_message" :to_id="client.user"></sendMessage>
                        <changeMessage v-if="show_change_message" :message="message_for_change"
                            @messageIsChanged="messageIsChanged"></changeMessage>
                    </div>

                    <infoContainer :user_id="client.user" :alowedChange="allowSendMessage" v-on:change="changeMessageText">
                    </infoContainer>

                </div>

            </div>

            <div class="content-right">

                <ul class="content-right__ul">
                    <li class="">Имя: {{ client.name }}</li>
                    <li class="">Дата рождения: {{ this.getDate(client.date_of_birth) }} г.</li>
                    <li class="">Телефон: <a :href='removeNonNumeric(client.number_phone)' class="number_phone_link"> {{
                        client.number_phone }}</a></li>
                    <li class="">E-mail: {{ client.email }}</li>
                    <li class="">Город обращения: {{ client.city }}</li>
                    <li class="">Номер договора: №{{ client.number_contract }}</li>
                    <li>
                        <div class="download_contract" v-if="client.file_pdf_contract">
                            <span class="download_contract-text" ref="fileText"> Договор:
                                <span @click="downloadUserFile()" style="float:right;" class="download_contract-icon"></span><br>

                                {{ client.file_pdf_contract
                                }}</span>

                        </div>
                    </li>
                    <li class="">Дата заключения: {{ this.getDate(client.date_start_contract) }} г.</li>
                    <li class="">Сумма по договору: {{ client.contract_amount }}</li>
                    <li class="">Оплата по договору: {{ client.paid }}</li>


                    <li
                        :style="client.status_contract ? { 'backgroundColor': 'grey' } : { 'backgroundColor': 'var(--green)' }">
                        Статус: {{ client.status_contract ? "закончено" : "в работе" }}
                    </li>

                    <li>Вопрос обращения: {{ client.additionally }}</li>

                </ul>


                <p><router-link :to="{ name: selectComponent, params: { clientId: this.$route.params.clientId } }"
                        class="bottom_links">редактировать данные</router-link></p>

                <div style="background-color: var(--black); padding:6px; margin-top:20px; border-radius:11px">

                    <RatingUser :title="title_button" :count="client.rating" style="margin:0"></RatingUser>
                    <p style="margin:-10px 0 10px;margin-bottom:10px">обновлено: {{ getDateRating }} </p>
                </div>

            </div>

        </div>
    </div>
</template>

<style>
.mess {
    position: absolute;
    display: inline-block;
    width: auto;
    z-index: 9999;
}

.mess_cont {
    top: -40px;
    right: 0;
    position: absolute;

}

ul>li:last-child {
    background-color: white;
    color: black;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}



.head-info {
    text-align: left;
    padding: 10px;
}

.lawyer {
    font-size: 22px;
}

.head-info>a {
    font-family: regular;
    font-size: 22px;
}
</style>