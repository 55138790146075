<script>
import axios from "@/roleGuard"
import { baseURL } from "@/config";
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { createNewFormData } from '@/utils'

export default {
    name: "sendMessage",
    data() {
        return {
            message: {
                text: "",
                file: "",
            },
            fileName: '',
            request: {
                loading: false
            }
        }
    },
    props: {
        to_id: Number
    },
    computed: {
        ...mapGetters('main', ['getUser']),
        fileExists() {
            if (this.message.file) {
                return true
            }
            else {
                return false
            }
        }
    },
    methods: {
        ...mapActions('main', ['GET_USER_INFO']),
        ...mapMutations('main', ['addMessage']),

        handleFileUpload(event) {
            const file = event.target.files[0]
            this.message.file = file;
            if (file) {
                this.fileName = file.name
            }
            else {
                this.fileName = ''
            }

        },

        clearFile() {
            this.message.file = ''
            this.fileName = ''
        },
        changeInput() {
            this.created.show = false
        },
        sendMessage() {
            this.request.loading = true
            const url = baseURL + "messages/create"
            const data = {
                from_user: this.getUser.id,
                text: this.message.text,
                file: this.message.file,
                to_user: this.to_id,
            }

            const headers = {
                'Content-Type': 'multipart/form-data'
            }
            const formData = createNewFormData(data, [])
            axios.post(url, formData, { headers: headers })
                .then((response) => {
                    this.addMessage(response.data)
                    Object.keys(this.message).forEach(key => {
                        this.message[key] = '';
                    });
                })
                .catch(() => {

                })
                .finally(() => {
                    this.fileName = ''
                    this.request.loading = false
                })


        }
    },
    mounted() {
        this.GET_USER_INFO()
    }


}
</script>

<template>
    <div class="send-message">
        <form action="" @submit.prevent="sendMessage()">
            <div style="position: relative;">

                <div style="position: relative;">
                    <textarea rows="7" v-model="this.message.text" maxlength="200" required
                        placeholder="Введите текст сообщения...">
                </textarea>

                    <div class="icon-cont">

                        <input type="file" name="file" @change="handleFileUpload" placeholder="Файл не выбран" id="file"
                            accept=".doc,.docx, .xlsx, .xls, .pdf " ref="fileInput"> <!-- -->
                        <label for="file" class="input-label">
                            <i class="fa fa-file-pdf-o" aria-hidden="true"> </i>
                        </label>
                        <i v-if="fileExists" class="fa fa-times-circle" @click="clearFile()" aria-hidden="true"></i>

                    </div>
                </div>


                <label for="file" class="input-label">
                    <p class="input__file-text">
                        {{ fileName }}</p>
                </label>
            </div>



         
            <v-btn :loading = "request.loading" type="submit" class="button" variant="text">ОТПРАВИТЬ СООБЩЕНИЕ</v-btn>
 
        </form>
    </div>
</template>

<style scoped>
.status-message {
    margin: 0
}

.message {
    display: inline-block;
    width: auto;
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0.9px;
}

.fa {
    color: black;
    margin-left: 10px;
    cursor: pointer;
}

.send-message {
    width: 100%;
    background: var(--blue);
    padding: 10px;
    border-radius: 11px;
    margin: 15px 0;
}

.icon-cont {

    position: absolute;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

textarea {
    outline: none;
    font-size: 18px;
    width: 100%;
    padding: 10px;
    border-radius: 11px;
    margin-bottom: 5px;
}

.button {
    font-size: 15px;
    font-weight: 600;
    width:100% ;
    font-family: pro;
    color: white;
    cursor: pointer;
    border: 0;
    background: 0;
}

form>div {
    text-align: left;
}


#file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file-icon-wrapper {
    margin-left: 0px;
    margin-top: -40px;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


.input__file-icon-wrapper::before {

    padding: 0 10px;
    content: '\e060';
    font-size: 30px;
    color: black;
    font-family: 'typeicons';
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    height: 1em;
    font-size: 1em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.input__file-text {
    font-size: 10px;
    color: rgba(240, 248, 255, 0.941);
    padding-left: 4px;
}

.input-label {
    text-align: left;
    cursor: pointer;
}
</style>