import axios from '@/roleGuard'

export function generate_password() {
    const length = 10; // Длина пароля
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    let hasNumber = false;
    let hasUppercase = false;

    while (password.length < length || !hasNumber || !hasUppercase) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        const character = charset[randomIndex];

        if (!hasNumber && /\d/.test(character)) {
            hasNumber = true;
        } else if (!hasUppercase && character === character.toUpperCase() && character !== character.toLowerCase()) {
            hasUppercase = true;
        }

        password += character;
    }

    return password;
}

export function logout_from_profile(router) {
    localStorage.removeItem("token")
    localStorage.removeItem("role")
    router.push("/authForm")
}

export function formatName(fullName) {

    const nameParts = fullName.split(' ');
    // Получаем фамилию
    if (nameParts < 3) { return fullName }
    const lastName = nameParts[0];

    // Получаем инициалы
    const initials = nameParts.slice(1).map(name => name[0] + '.').join(' ');

    // Формируем новую строку
    const formattedName = `${lastName} ${initials}`;

    return formattedName;

}
export function isAdmin() {
    const role = localStorage.getItem("role")
    return role === 'admin';
}
export function isEmployee() {
    const role = localStorage.getItem("role")
    return role === 'employee';
}

export function clearFilter(filter) {
    Object.keys(filter).forEach(key => {
        filter[key] = false;
    });

}

export function download(url) {
    axios.get(url, {
        responseType: 'blob' // Устанавливаем тип ответа как blob
    })
        .then(response => {
            const disposition = response.headers['content-disposition'];
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
            let filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'download'; // Получаем имя файла из заголовка

            if (decodeURIComponent(filename) !== filename) {
                filename = decodeURIComponent(filename);
            }
            
            const blob = new Blob([response.data]); // Создаем Blob из полученных данных
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Устанавливаем имя файла для скачивания
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Ошибка загрузки файла:', error);
        });
}
export const breakPoints = [200, 900, 1260]
export function getWidthWindow() {
    return window.innerWidth
}


export function checkStatus(error) {
    const data = error.response.data
    const response_messages = Object.values(data).flat();
    const modifiedMessages = response_messages.map(message => {
        if (message.includes('base model с таким email уже существует')) {
            return 'Пользователь с таким email уже существует';
        }
        return message;
    });
    const concatenatedString = modifiedMessages.join('\n');
    return concatenatedString
}

export function getLocalTime(server_date) {
    const date = new Date(server_date)
    const localDateTime = date.toLocaleString()
    let arr = localDateTime.split(",")
    let timeString = arr[1]; // вот ваша строка времени

    // Разделим строку на часы, минуты, секунды и AM/PM
    let timeParts = timeString.split(':');
    let hours = timeParts[0];
    let minutes = timeParts[1];
    // let ampm = timeParts[2].split(' ')[1]; // AM/PM

    // Соберем время без секунд
    let newTimeString = hours + ':' + minutes
    arr[1] = newTimeString
    return arr
}

export function formateDateForInputDateField(new_date) {
    let arr = new_date.split('/').reverse()
    let day = arr[2]
    let month = arr[1]
    if (Number(day) < 10) { day = '0' + day }
    if (Number(month) < 10) { month = '0' + month }
    arr[2] = month
    arr[1] = day
    const result = arr.join("-")
    return result
}

export function passwordIsValid(password) {
    const regexUpperCase = /[A-Z]/;
    const regexNumber = /[0-9]/;
    const hasUpperCase = regexUpperCase.test(password);
    const hasNumber = regexNumber.test(password);
    const isLengthValid = password.length >= 8;
    return hasUpperCase && hasNumber && isLengthValid;
}

export function areObjectsEqual(obj1, obj2) {
    function isObject(obj) {
        return obj !== null && typeof obj === 'object';
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        const areObjects = isObject(val1) && isObject(val2);

        if (
            (areObjects && !areObjectsEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}


export function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}


export function createNewFormData(obj, exlude) {

    let data = new FormData()
    const keys = Object.keys(obj)
    for (const key of keys) {
        if (exlude.indexOf(key) === -1) {
            data.append(key, obj[key])
        }
    }

    return data
}

