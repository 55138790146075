<script>
export default {
    name: "accessDenied",
    components: {
     
    },
    data() {
        return {
            title: "Проблемы с доступом"
        }
    }
}
</script>

<template>
  
        <div class="flex-center">

            <div class="auth-message">
                <div style="" class="message">
                    <p>Доступ запрещен</p>
                </div>
                <RouterLink to="/authForm" class="bottom_links">вернуться на главную страницу</RouterLink>
            </div>

        </div>
  
</template>

<style scoped>

.auth-message {
    margin: 20px;
    font-family: regular;
}

.message {
    padding: 10px;
    border-radius: 12px;
    margin:20px 0;
    font-size:30px;
    background:var(--red);
}

.bottom_links {
    font-size: 20px;
 
}

.auth-message>p {
    font-size: 20px;
}
</style>
