<template>
  <mainWrapper></mainWrapper>
  
</template>

<script>
import mainWrapper from "./components/main-wrapper.vue"


export default {
  name: 'App',
  components: {
    mainWrapper, 
   
  }
}
</script>

<style>




#app {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;

}
.main-wrapper{
  flex: 1;
}
.footer{
  text-align: center;
  position: relative; 
}
</style>
