
import { baseURL } from '@/config';
import axios from "@/roleGuard"

export default {
    namespaced: true,
    state: {
        user_data: {
            email: "",
            id_from_db: "",
            first_name: ""
        },
        employees: [],
        filtered_employees: [],
        clients: [],
        cities: [],
        filtered_clients: [],
        company: [],
        messages: [],
        urls: { prev: '', current: '' }
    },

    getters: {
        allClients(state) { return state.clients },
        allEmployees(state) { return state.employees },
        allCities(state) { return state.cities },
        getUser(state) { return state.user_data },
        getCompanyInfo(state) { return state.company },
        getFilteredClients(state) { return state.filtered_clients },
        getFilteredEmployees(state) { return state.filtered_employees },
        getMessages(state) { return state.messages },
        getPrev(state) { return state.urls.prev },
        getCurrent(state) { return state.urls.current },
       

    },
    mutations: {
        setUrls(state, current) {
            state.urls.prev = state.urls.current
            state.urls.current = current

        },
        setUserInfo(state, user) {
            state.user_data = user
        },
        setEmail(state, email) {
            state.user_data.email = email
        },
        setFilteredClients(state, clients) {
            state.filtered_clients = clients;
        },
        setFilteredEmployees(state, employees) {
            state.filtered_employees = employees;
        },
        setClients(state, clients) {
            state.clients = clients;
        },
        setEmployees(state, employees) {
            state.employees = employees;
        },
        setCities(state, cities) {
            state.cities = cities;
        },
        setCompanyInfo(state, company) {
            state.company = company
        },

        setMessages(state, messages) {
            state.messages = messages
        },
        addMessage(state, message) {
            state.messages.unshift(message)
        },
        deleteMessageInStore(state, messageId) {
            state.messages = state.messages.filter(message => message.id !== messageId);
        },
        updateMessage(state, updatedMessage) {
            const index = state.messages.findIndex(msg => msg.id === updatedMessage.id);
            if (index !== -1) {
                Object.assign(state.messages[index], updatedMessage);
            }
        },

    },
    actions: {
        GET_CLIENTS_FROM_API({ commit }) {
            const url = baseURL + 'client/list'
            axios.get(url)
                .then((response) => {
                    commit('setClients', response.data),
                        commit('setFilteredClients', response.data)
                })
                .catch(error => console.log(error))
        },
        GET_EPLOYEES_FROM_API({ commit }) {
            const url = baseURL + 'employee/list'
            axios.get(url)
                .then(response => {

                    const sort = response.data.sort((a, b) => b.rating - a.rating);

                    commit('setEmployees', sort)
                    commit('setFilteredEmployees', sort)
                })
                .catch(error => console.log(error))
        },
        loadCities({ commit }) {
            // Загрузите данные из JSON-файла
            const citiesData = require('@/cities.json');
            commit('setCities', citiesData);
        },
        GET_USER_INFO({ commit }) {
            const url = baseURL + "user/info"
            axios.get(url)
                .then((response) => {

                    commit('setUserInfo', response.data)
                })
                .catch(e => console.log(e))
        },

     
    },

}

