<script>
import axios from "@/roleGuard"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { generate_password, createNewFormData, passwordIsValid } from '@/utils'; // Импортируйте функцию
import { baseURL } from "@/config";

import vNavigationPanel from "@/components/v-navigation-panel.vue";
import vFlags from "../../v-flags.vue"
import { vDialog, vBtn } from 'vuetify'


export default {
    name: "createClient",
    components: {
        vFlags,
        vNavigationPanel,
        vDialog, vBtn
    },
    data() {
        return {
            title: "Создание юриста",
            employee: {
                name: "",
                email: "",
                password: "",
                date_of_birth: "",
                number_phone: "",
                number_contract: "",
                date_start_contract: "",
                city: "",
                file_pdf_contract: "",
                additionally: ""
            },

            isValid: true,
        }
    },
    computed: {
        ...mapGetters('main', ['allCities']),
        ...mapGetters('request', ['getLoading', 'getModal']),
    },
    methods: {
        ...mapActions('main', ['loadCities']),
        ...mapMutations("request", ['setResponse', 'setLoading', 'setModal']),


        generateNewPassword() {
            const password = generate_password();
            const input = this.$refs.password_input;
            input.value = password;
            this.employee.password = password;
            this.isValid = true
        },
        handleFileUpload(event) {
            this.employee.file_pdf_contract = event.target.files[0];
        },

        handlePasswordInput() {
            this.isValid = true
        },
        close(){
            if(window.history.length > 2){
                this.$router.go(-1)
            }
            else{
                this.$router.push("/")
            }
        },
        createEmployee() {
            this.setLoading(true)
            if (!passwordIsValid(this.employee.password)) {
                this.setModal(false)
                this.setResponse({ status: false, message: 'Слишком простой пароль' })
                return
            }
            const url = baseURL + "employee/create";
            const data = createNewFormData(this.employee, [])
            axios.post(url, data)
                .then((response) => {
                    this.setResponse({ status: true, message: response.data.message })
                    this.employee = {}
                    this.employee.city = ""
                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                    this.setModal(false)
                })

        },
        checkMinLength() {
            const inputValue = this.employee.number_phone;
            const inputElement = this.$refs.phoneInput;

            if (inputValue.length < 18) {
                inputElement.setCustomValidity('Введите номер телефона полностью.');
            } else {
                inputElement.setCustomValidity('');
            }

            inputElement.reportValidity(); // Для показа сообщения об ошибке валидации
        }

    }, mounted() {
        this.loadCities()
    }

}

</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel></vNavigationPanel>

        <div>
            <form action="" class="content" @submit.prevent="setModal(true)">
                <div class="content-left">
                    <div class="input_group">
                        <p class="lk_input_p">ФИО</p>
                        <input title="ФИО не должно содержать посторонних символов" required v-model="employee.name"
                            type="text" class="lk_input" placeholder="Введите ФИО" pattern="[А-Яа-яA-Za-z\s]+">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">E-mail</p>
                        <input required v-model="employee.email" type="email" class="lk_input" placeholder="Введите е-mail">
                    </div>
                    <div class="input_group">
                        <p class="lk_input_p">Город работы</p>
                        <div class="costum-select">
                            <select name="" id="" class="" v-model="employee.city">
                                <option disabled value="">Выберите город</option>
                                <option v-for="city in allCities" :key="city.id" :value="city.city">{{ city.city }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input_group">
                        <p class="lk_input_p">Трудовой договор</p>
                        <input required v-model="employee.number_contract" type="text" class="lk_input" placeholder="№ 12">

                    </div>
                    <div class="input_group">
                        <p class="lk_input_p">Дата заключения договора</p>
                        <input required v-model="employee.date_start_contract" type="date" max='2050-01-01' min='1900-01-01'
                            class="lk_input">


                    </div>
                    <div class="input_group" style="text-align:left; margin:10px 0">
                        <p class="lk_input_p">Прикрепить файл договора</p>
                        <label for="pdf" class="custom-file-upload">

                            <span v-if="employee.file_pdf_contract">{{ employee.file_pdf_contract.name }}</span>
                            <span v-else>Выбрать файл</span>

                            <input required class="input-file" style="display: none;" type="file" id="pdf" name="file"
                                accept=".doc, .xlsx, .pdf, .xls, .docx" @change="handleFileUpload" ref="fileInput">

                        </label>
                    </div>


                </div>

                <div class="content-right">
                    <div class="input_group">
                        <p class="lk_input_p">Дата рождения</p>
                        <input required v-model="employee.date_of_birth" type="date" max='2050-01-01' min='1900-01-01'
                            class="lk_input">
                    </div>
                    <div class="input_group">
                        <p class="lk_input_p">Номер телефона</p>
                        <input ref="phoneInput" required v-model="employee.number_phone" type="text" class="lk_input"
                            @input="checkMinLength" v-phone>
                    </div>


                    <div class="input_group">
                        <p class="lk_input_p" @click="generateNewPassword()">Пароль <span style="float:right;" href=""
                                class="bottom_links">сгенеририровать
                                новый</span></p>
                        <input required @input="handlePasswordInput" v-model="employee.password" type="text"
                            ref="password_input" class="lk_input" placeholder="Введите пароль">
                    </div>



                    <div class="input_group">
                        <p class="lk_input_p">Дополнительно</p>
                        <textarea v-model="employee.additionally" type="text" class="lk_input"
                            style="height:140px;align-items: top;padding-top:20px; "
                            placeholder="Дополнительная ифнормациая о юристе..." maxlength="140"></textarea>

                    </div>
                    <div class=" button-group" style="margin:27px 0">
                        <button class="lk__btn">СОЗДАТЬ ЮРИСТА</button>
                        <p class="bottom_links" @click="close()">отменить создание и выйти</p>
                    </div>
                </div>


            </form>



            <v-dialog width="300px" v-model="getModal" @click:outside="setModal(false)">
                <template v-slot:default="{ }">
                    <div>
                        <form class="modal" @submit.prevent="createEmployee()">
                            <h2>Продолжить?</h2>
                            <div class="buttons">
                                <v-btn :loading="getLoading" type="submit"
                                    color="var(--green)"><span>Сохранить</span></v-btn>
                                <v-btn color="var(--red)" @click="setModal(false)"><span>Выйти</span></v-btn>
                            </div>
                        </form>
                    </div>
                </template>
            </v-dialog>

        </div>
    </div>
</template>

<style scoped>
.button-group {
    width: 100%;
}

.input-file {
    color: white;
    margin-top: 5px;
    margin-bottom: -19px
}

.lk__btn {
    margin-top: 20px;
    max-width: 400px;
    width: 100%;
    margin: auto auto;
}
</style>