<script>
import axios from "@/roleGuard"
import { baseURL } from "@/config"
import { formatName, download, getLocalTime } from "@/utils"
import vFlags from "@/components/v-flags.vue"
import sendMessage from "@/components/send-message.vue"
import infoContainer from "@/components/info-container.vue"
import RatingUser from "@/components/rating-user.vue"
import changeMessage from "@/components/change-message.vue"
import vNavigationPanel from "@/components/v-navigation-panel.vue"
export default {
    name: "yuristProfile",
    components: {
        vFlags,
        sendMessage,
        infoContainer,
        RatingUser,
        changeMessage,
        vNavigationPanel
    },
    data() {
        return {
            title: "",
            title_button: "рейтинг юриста",
            employee: {},
            his_clients: {},
            searchClient: '',
            client_filter: [],
            search_client_show: false,

            show_his_clients: false,

            show_send_message: true,
            show_change_message: false,
            message_for_change: Object,
            changed: {
                status: false,
                show: false,
                // message: "отредактировано"
            }

        }
    },
    computed: {

    },
    methods: {
        changeMessageText(message) {
            this.show_send_message = false,
                this.show_change_message = true,
                this.message_for_change = message

        },
        messageIsChanged(status) {
            this.changed.show = true
            this.show_send_message = true
            this.show_change_message = false

            if (status) {
                this.changed.status = true
                // this.changed.message = "отредактировано"
            }
            else {
                this.changed.status = false
                // this.changed.message = "Ошибка. Перезагрузите странциу."
            }

            setTimeout(() => {
                this.changed.show = false
            }, 3000);
        },
        close() {
            this.$router.push("/lk/admin")
        },
        fetchEmployeeProfile(employeeId) {
            const url = baseURL + `employee/${employeeId}`

            axios.get(url)
                .then(response => {
                    this.employee = response.data
                    this.his_clients = response.data.his_clients
                }
                )
                .catch(e => 
                {
                    const status = e.response.status
                    if (status==403){
                        this.$router.push('/access-denied')
                    }
                    else if(status == 404){
                        this.$router.push({name:'not-found'})
                    }
                
                })
        },


        clearInputClient() {
            this.client_filter = []; // Если поле ввода пусто, очистите результат
            this.search_client_show = false
            this.searchClient = ''
        },
        filteredClients() {
            if (this.searchClient.trim() === '') {
                this.client_filter = []; // Если поле ввода пусто, очистите результат
                this.search_client_show = false
                return;
            }
            this.search_client_show = true
            // Фильтрация клиентов на основе введенного текста
            const filtered = this.his_clients.filter(client =>
                client.name.toLowerCase().includes(this.searchClient.toLowerCase())
            );

            this.client_filter = filtered
        },

        showHisClients() {
            if (this.show_his_clients == true) {
                this.show_his_clients = false
            }
            else { this.show_his_clients = true }

        },
        getDate(server_date) {
            return getLocalTime(server_date)[0]
        },
        formatNameComponent(name) {
            return formatName(name)
        },
        downloadUserFile() {
            const id = this.employee.user
            const url = baseURL + `user/${id}/download-file`
            download(url)
        },
        removeNonNumeric(inputString) {
            if (inputString) {
                return 'tel:' + inputString.replace(/[\s()-]/g, '');
            }
        },
    },

    created() {
        const employeeId = this.$route.params.employeeId;
        // Теперь вы можете использовать employeeId для отправки запроса
        this.fetchEmployeeProfile(employeeId);
    },

}
</script>

<template v-if="employee.name">
    <div class="lk-page">

        <vFlags v-if="employee.name" :text_flags="formatNameComponent(employee.name)"></vFlags> <!--formated(client.name)-->
        <vNavigationPanel></vNavigationPanel>

        <div class="content">
            <div class="content-left">


                <p class="lk_input_p">Быстрый поиск клиента</p>
                <input type="text" class="lk_input" v-model="searchClient" @input="filteredClients"
                    placeholder="Введите фио клиента...">
                <div class="result_search" v-if="this.search_client_show">
                    <p class="result-a" style="font-family: pro; " v-if="!client_filter.length" @click="clearInputClient()">
                        Очистить резльтаты поиска</p>
                    <router-link class="result-a" v-for="client in client_filter" :key="client.id"
                        :to="{ name: 'watchClient', params: { clientId: client.id } }">{{ client.name }}</router-link>
                </div>


                <ul class="lk_navigation">
                    <router-link :to="{ name: 'employeeClientSpisok', params: { employeeId: employee.id } }"
                        class="bottom_line">Список клиентов</router-link>
                </ul>



                <div style="position: relative;">
                    <!-- <div class="mess_cont">
                        <div class="status-message mess" style="position: relative;" v-if="changed.show">
                            <div :style="{ backgroundColor: changed.status ? 'var(--green)' : 'var(--red)' }"
                                class="message">
                                <p style="font-size:12px">{{ changed.message }}</p>
                            </div>
                        </div>

                    </div> -->
                    <sendMessage v-if="show_send_message" :to_id="employee.user"></sendMessage>
                    <!-- v-on:change="messageIsChanged" -->
                    <changeMessage v-if="show_change_message" :message="message_for_change"
                        @messageIsChanged="messageIsChanged"></changeMessage>
                    <infoContainer :alowedChange="true" v-if="employee.user" :user_id="employee.user"
                        v-on:change="changeMessageText">
                    </infoContainer>
                </div>

            </div>

            <div class="content-right" v-if="employee.date_of_birth">

                <ul class="content-right__ul">
                    <li class="">Имя: {{ employee.name }}</li>
                    <li class="">Дата рождения: {{ this.getDate(employee.date_of_birth) }} г.</li>
                    <li class="">Телефон: <a :href='removeNonNumeric(employee.number_phone)' class="number_phone_link"> {{
                        employee.number_phone }}</a></li>
                    <li class="">E-mail: {{ employee.email }}</li>
                    <li class="">Город работы: {{ employee.city }}</li>
                    <li class="">Трудовой договор: №{{ employee.number_contract }}</li>
                    <li>
                        <div class="download_contract" v-if="employee.file_pdf_contract">
                            <span class="download_contract-text" ref="fileText"> Договор: <span style="float:right;" @click="downloadUserFile()"
                                    class="download_contract-icon"></span> <br>{{ employee.file_pdf_contract
                                    }}</span>

                        </div>
                    </li>
                    <li class="">Дата заключения: {{ this.getDate(employee.date_start_contract) }} г.</li>
                    <li class="">Договоров у юриста: {{ employee.active_contract }}</li>
                    <li class="">Заключено на сумму: {{ employee.total_sum_contract }}</li>
                    <li class="">Должники: {{ employee.obligation }}</li>
                    <li>Дополнительно: {{ employee.additionally }}</li>
                </ul>
                <p><router-link :to="{ name: 'changeYuristprofile', params: { employeeId: this.$route.params.employeeId } }"
                        class="bottom_links">редактировать данные</router-link></p>
                <RatingUser :title="title_button" :count="employee.rating"></RatingUser>
            </div>

        </div>
    </div>
</template>

<style scoped>
ul>li:last-child {
    background-color: white;
    color: black;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}



.mess_cont {
    top: 218px;
    right: 0;
    position: absolute;

}


.bottom_links {
    font-size: 18px;
}

.lk_navigation {
    cursor: pointer
}
</style>