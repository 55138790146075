
<script>
import { baseURL } from "@/config";
import vMessage from "./v-message.vue"

import axios from '@/roleGuard'
import { mapMutations, mapGetters } from "vuex";
import Paginate from "vuejs-paginate-next"

export default {
    name: "infoContainer",
    components: {
        vMessage,
        Paginate
    },
    props: {
        user_id: Number,
        alowedChange:Boolean
    },
    data() {
        return {
            currentPage: 1,
            messagesPerPage: 3,
        }
    },
    computed: {
        ...mapGetters('main',['getMessages']),
        displayedMessages() {
            const startIndex = (this.currentPage - 1) * this.messagesPerPage;
            const endIndex = startIndex + this.messagesPerPage;
            return this.getMessages.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.getMessages.length / this.messagesPerPage);
        },



    },
    methods: {
        ...mapMutations('main',['setMessages']),

        goToPage(pageNumber) {
            this.currentPage = pageNumber;
        },

        /**слушатель событий из дочернего компонента */
        emitToParent(message) {
            // перебрасываем на еще один уровень вверх
            this.$emit("change", message)
        },

        loadMessageById(user_id) {
            const url = baseURL + `messages/list/${user_id}`
            axios.get(url, { user_id: user_id })
                .then((response) => {
                    this.setMessages(response.data)
                })
                
        },

        loadMyMessage() {
            const url = baseURL + 'messages/mylist'
            axios.get(url)
                .then((response) => {
                    this.setMessages(response.data)
                })
                
        },

    },
    beforeMount(){
        if (this.user_id) {
            this.loadMessageById(this.user_id)
            
        }
        else {
            this.loadMyMessage()
        }
    },
    unmounted(){
        this.setMessages([]) 
    }


}
</script>
<template>
    <div class="info-container">
        <p v-if="getMessages.length">информация:</p>
        <p v-if="!getMessages.length">сообщений нет.</p>

        <div class="" v-if="getMessages.length">

            <div class="messages">
                <vMessage v-for="( message) in displayedMessages " :key="message.id" 
                    :message="message" v-on:change="emitToParent"
                    :alowedChange = "alowedChange"
                    >
                </vMessage>
            </div>



            <Paginate v-if="getMessages.length > messagesPerPage" :page-range='3' :initial-page="currentPage"
                :page-count="totalPages" :click-handler="goToPage" :active-class="'nav-activ-a'" :break-view-text="'...'"
                :prev-text="'<'" :next-text="'>'" :container-class="'nav-cont'" :page-class="'nav-li'"
                :page-link-class="'nav-a'" :prev-link-class="'nav-a'" :prev-class="'nav-li'" :next-link-class="'nav-a'"
                :next-class="'nav-li'">

            </Paginate>
        </div>
    </div>
</template>
<style scoped>
.info-container {
    font-family: regular;
    color: white;
}

.info-container>p {
    padding: 8px;
    font-weight: 400;
    font-size: 20px;
}
</style>