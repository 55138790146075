<script>
import axios from 'axios'
import { baseURL } from '@/config';

import {mapGetters, mapMutations} from "vuex"
export default {
    name: "authConfirm",
    components:{
    
    },
    computed:{
        ...mapGetters("main", ['getUser']),

    },
    data() {
        return {
            code: "",
        }
    },
    methods: {
        ...mapMutations("request", ['setResponse']),
        verification() {
            this.$refs.btn.setAttribute("disabled",'')
            this.$refs.btn.innerText = "ЗАПРОС ОТПРАВЛЕН"
            const url = baseURL + 'auth/verification'
            const data = {
                email: this.getUser.email,
                code: this.code
            }
            
            axios.post(url, data, { withCredentials: true })
                .then((response) => {
                    localStorage.setItem("token",response.data.token)
                    localStorage.setItem("role", response.data.user_type)
                    const role = response.data.user_type
                    if (role === 'admin'){
                        this.$router.push("/admin/")
                    }
                    else if(role === 'employee'){
                        this.$router.push("/employee/")
                    }
                    else{
                        this.$router.push("/client/")
                    }
                    
                })
                .catch((e) => { 
                    
                    this.setResponse({status:false, message:e.response.data.message})
                    this.$refs.btn.innerText = "ПОДТВЕРДИТЬ ВХОД"
                    this.$refs.btn.removeAttribute("disabled",'')
                })
        }
    }
}
</script>

<template>
   
    <div class="auth-confirm">
       
        <p class="clue"><b style="font-weight: 800;">2 шаг</b>: Введите код, который пришел на Ваш E-mail</p>
        <form action="" @submit.prevent="verification()" class="auth-form__form">
            <p class="input_text">Введите код</p>
            <input v-model="code" type="number" class="auth-form__inp" placeholder="XXXXХХ" minlength="6" required>
            <button class="lk__btn" ref = "btn">
                ПОДТВЕРДИТЬ ВХОД
            </button>
        </form>
        <RouterLink to="/authForm" class="bottom_links">вернуться на главную страницу</RouterLink>
    </div>
</template>

<style>
.auth-confirm {
    text-align: center;
    margin: auto auto;
    color: white;
}
</style>