<!-- Изменения личных данных -->

<script>
import vFlags from "./v-flags.vue"
import {
    generate_password, isAdmin, createNewFormData
} from '@/utils';

import { mapGetters, mapActions, mapMutations } from "vuex"
import { baseURL } from "@/config"
import vNavigationPanel from "./v-navigation-panel.vue"
import { vDialog, vBtn } from 'vuetify'
import axios from "@/roleGuard"

export default {
    name: "changeSettings",
    components: {
        vFlags, vNavigationPanel,
        vDialog, vBtn
    },
    data() {
        return {
            title: "Изменения данных",
            show_number_input: true,
            form: {
                email: '',
                first_name: '',
                number_phone: '',
                password: ''
            },

            isChenged: true,
            show_confirmation: false,
            isValid: true,
            isEmailValid: true
        }
    },
    computed: {
        ...mapGetters('main', ['getUser']),
        ...mapGetters('request', ['getLoading', 'getModal']),

        showNumberInput() {
            return isAdmin()
        },
    },
    methods: {
        ...mapActions('main', ['GET_USER_INFO', '']),
        ...mapMutations("request", ['setResponse', 'setLoading', 'setModal']),
        set_user_in_input() {
            const user = this.getUser;
            this.form.email = user.email
            this.form.first_name = user.first_name
            if (this.getUser.profile) {
                this.form.number_phone = this.getUser.profile.number_phone
            }
        },

        generateNewPassword() {
            const password = generate_password();
            this.form.password = password
        },

        changeUserProfile() {
            this.setLoading(true)
            const url = baseURL + `user/settings/${this.getUser.id}`;
            const formData = createNewFormData(this.form, [])
            axios.put(url, formData)
                .then((response) => {
                    this.setResponse({ status: true, message: response.data.message })

                    const new_url = baseURL + "user/info"
                    axios.get(new_url)
                        .then()
                        .catch(() => location.reload())

                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                    this.setModal(false)
                })

        },

    },

    created() {
        this.GET_USER_INFO()
        this.set_user_in_input()
    },

    watch: {
        'getUser': function (user) {
            if (user) {
                this.set_user_in_input()
            }
        },
    }

}
</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel></vNavigationPanel>

        <form @submit.prevent="setModal(true)">
            <div class="content">
                <div class="content-left">
                    <div class="input_group">
                        <p class="lk_input_p">Введите ФИО</p>
                        <input required type="text" pattern="[А-Яа-яA-Za-z\s]+" v-model="form.first_name"
                            placeholder="Введите ФИО" class="lk_input">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Введите е-mail </p>
                        <input required type="email" v-model="form.email" placeholder="Введите email" class="lk_input">
                    </div>
                </div>

                <div class="content-right">

                    <div class="input_group" v-if="!showNumberInput">
                        <p class="lk_input_p">Номер телфона</p>
                        <input ref="phoneInput" @input="handleChanges" required type="tel" v-model="form.number_phone"
                            class="lk_input" v-phone placeholder="8 (999) 999-99-99">
                    </div>


                    <div class="input_group">
                        <p class="lk_input_p" @click="generateNewPassword()">Пароль
                            <span style="float:right;" href="" class="bottom_links">
                                сгенеририровать
                                новый
                            </span>
                        </p>
                        <input type="text" v-model="form.password" placeholder="Введите новый пароль" class="lk_input">
                        <p v-if="isValid"></p>
                    </div>

                </div>

            </div>
            <!--v-slot:activator="{ props }"-->
            <button type="submit" class="lk__btn">СОХРАНИТЬ ДАННЫЕ</button>
        </form>
        <v-dialog width="300px" v-model="getModal" @click:outside="setModal(false)">
            <template v-slot:default="{ }">
                <div>
                    <form class="modal" @submit.prevent="changeUserProfile()">
                        <h2>Продолжить?</h2>
                        <div class="buttons">
                            <v-btn :loading="getLoading" type="submit" color="var(--green)"><span>Сохранить</span></v-btn>
                            <v-btn color="var(--red)" @click="setModal(false)"><span>Выйти</span></v-btn>
                        </div>
                    </form>
                </div>
            </template>
        </v-dialog>

        <p class="bottom_links" @click="this.$router.push('/')">отменить изменения и выйти</p>





    </div>
</template>

<style scoped>
.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.lk__btn {
    margin-top: 32px;
}
</style>