<script>
import axios from '@/roleGuard'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { baseURL } from "@/config";
import { generate_password, download, createNewFormData, passwordIsValid } from '@/utils';

import vFlags from "../../v-flags.vue"
import vNavigationPanel from "@/components/v-navigation-panel.vue";
import { vDialog, vBtn } from 'vuetify'


export default {
    name: "adminChangeclient",
    components: {
        vFlags, vNavigationPanel,
        vDialog, vBtn
    },
    data() {
        return {
            title: "Изменения данных клиента",
            client: {},
            file_pdf_contract: "",


            isPasswordValid: true,
            isChenged: true,
            isDeleted: false
        }
    },
    computed: {
        ...mapGetters('main', ['allCities', 'allEmployees']),
        ...mapGetters('request', ['getLoading', 'getModal']),

    },
    methods: {
        ...mapActions('main', ['GET_EPLOYEES_FROM_API', 'loadCities']),
        ...mapMutations("request", ['setResponse', 'setLoading', 'setModal']),
        close(){
            if(window.history.length > 2){
                this.$router.go(-1)
            }
            else{
                this.$router.push("/")
            }
        },
        handleFileUpload(event) {
            this.client.file_pdf_contract = event.target.files[0];
        },
        handleInputEmployee(event) {
            const id = event.target.value
            const lawyer = this.allEmployees.find(obj => obj.id == id)
            const city = lawyer.city
            this.client.city = city
            this.client.lawyer = lawyer.id
        },
        generateNewPassword() {
            const password = generate_password();
            this.client.password = password;
        },

        fetchClient(id) {
            const url = baseURL + `client/${id}`

            axios.get(url)
                .then(response => {
                    this.client = response.data
                    this.client.date_of_birth = this.client.date_of_birth.split('T')[0]
                    this.client.date_start_contract = this.client.date_start_contract.split('T')[0]
                    this.file_pdf_contract = this.client.file_pdf_contract
                    this.client.file_pdf_contract = ''
                    this.client.password = ''
                })
                .catch(e => 
                {
                    const status = e.response.status
                    if (status==403){
                        this.$router.push('/access-denied')
                    }
                    else if(status == 404){
                        this.$router.push({name:'not-found'})
                    }
                
                })
        },
        downloadUserFile() {
            const id = this.client.user
            const url = baseURL + `user/${id}/download-file`
            download(url)
        },


        updateClient() {
            if (this.client.password) {
                if (!passwordIsValid(this.client.password)) {
                    this.setModal(false)
                    this.setResponse({ status: false, message: 'Слишком простой пароль' })
                    return
                }
            }

            this.setLoading(true)
            const url = baseURL + `user/settings/${this.client.user}`;
            const data = {
                email: this.client.email,
                first_name: this.client.name,
                number_phone: this.client.number_phone,
                password: this.client.password
            }
            const formData = createNewFormData(data,[])
            axios.put(url, formData)
                .then((response) => {
                    // this.setResponse({ status: true, message: response.data.message })
                    const new_data = createNewFormData(this.client, [])
                    const id = this.client.id
                    const new_url = baseURL + `client/${id}/update`
                    axios.put(new_url, new_data)
                    .then(()=>{
                        this.setResponse({ status: true, message: response.data.message })
                    })
                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                    this.setModal(false)
                })

        },

        deleteClient() {
            this.setLoading(true)
            const id = this.$route.params.clientId;
            const url = baseURL + `client/${id}/destroy`
            axios.delete(url)
                .then(() => {
                    this.$router.push('/'),
                    this.setResponse({ status: false, message: "Пользователь удалён" })
                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                })
        },
        checkMinLength() {
            const inputValue = this.client.number_phone;
            const inputElement = this.$refs.phoneInput;

            if (inputValue.length < 18) {
                inputElement.setCustomValidity('Введите номер телефона полностью.');
            } else {
                inputElement.setCustomValidity('');
            }

            inputElement.reportValidity(); // Для показа сообщения об ошибке валидации
        }
    },
    created() {
        const id = this.$route.params.clientId;
        this.fetchClient(id)
        this.loadCities()
        this.GET_EPLOYEES_FROM_API()
    }

}

</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel></vNavigationPanel>

        <div>
            <form action="" class="content" @submit.prevent="setModal(true)">
                <div class="content-left">
                    <div class="input_group">
                        <p class="lk_input_p">ФИО</p>
                        <input required v-model="client.name" type="text" class="lk_input" placeholder="Введите ФИО"
                            pattern="[А-Яа-яA-Za-z\s]+">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">E-mail</p>
                        <input @input="handleEmailInput" required v-model="client.email" type="email" class="lk_input"
                            placeholder="E-mail">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Выбрать специалиста</p>
                        <div class="costum-select">
                            <select name="" id="" class="" v-model="client.lawyer" @input="handleInputEmployee">
                                <option disabled value="">Выберите специалиста</option>
                                <option v-for="lawyer in allEmployees" :key="lawyer.id" :value="lawyer.id">{{ lawyer.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Город обращения</p>
                        <div class="costum-select">
                            <select name="" id="" class="" v-model="client.city" disabled>
                                <option disabled value="">Поле заполниться автоматически</option>
                                <option v-for="city in allCities" :key="city.id" :value="city.city">{{ city.city }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input_group">
                        <p class="lk_input_p">Номер Договора</p>
                        <input required v-model="client.number_contract" type="text" class="lk_input" placeholder="№ 12">
                    </div>
                    <div class="input_group" style="text-align:left">
                        <p class="lk_input_p">Дата заключения договора</p>
                        <input required v-model="client.date_start_contract" type="date" max='2050-01-01' min='1900-01-01'
                            class="lk_input" format>
                    </div>
                    <div class="input_group" style="text-align:left; margin:10px 0">
                        <p class="lk_input_p">Статус дела</p>
                        <div class="costum-select"
                            :style="client.status_contract ? 'background:grey' : 'background:var(--green)'">
                            <select name="" id="" class="" v-model="client.status_contract">
                                <option :value=false>Действующее</option>
                                <option :value=true>Закончено</option>
                            </select>
                        </div>
                    </div>
                    <div class="input_group" style="text-align:left; margin:10px 0">
                        <p class="lk_input_p">Файл договора</p>
                        <div class="download_contract" v-if="file_pdf_contract">

                            <span class="download_contract-text" ref="fileText"> {{ file_pdf_contract
                            }}</span>
                            <p @click="downloadUserFile()" class="download_contract-icon" style="float:right;"></p>
                        </div>
                    </div>


                    <div class="input_group" style="text-align:left; margin:10px 0">
                        <p class="lk_input_p">Прикрепить файл договора</p>
                        <label for="pdf" class="custom-file-upload">

                            <span v-if="client.file_pdf_contract && client.file_pdf_contract.name">{{
                                client.file_pdf_contract.name }}</span>
                            <span v-else>Выбрать файл</span>
                            <input class="input-file" style="display: none;" type="file" id="pdf" name="file"
                                accept=".doc, .xlsx, .pdf, .xls, .docx" @change="handleFileUpload" ref="fileInput">

                        </label>
                    </div>



                </div>

                <div class="content-right">
                    <div class="input_group">
                        <p class="lk_input_p">Дата рождения </p>
                        <input required v-model="client.date_of_birth" type="date" max='2050-01-01' min='1900-01-01'
                            class="lk_input">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Номер телефона</p>
                        <input @input="checkMinLength" ref="phoneInput" required v-model="client.number_phone" type="text"
                            class="lk_input " v-phone placeholder="+79819191251">
                    </div>

                    <div class="input_group">
                        <p class="lk_input_p" @click="generateNewPassword()">Пароль <span style="float:right;" href=""
                                class="bottom_links">сгенеририровать
                                новый</span></p>
                        <input @input="handlePasswordInput" v-model="client.password" type="text" ref="password_input"
                            class="lk_input" placeholder="Введите новый пароль">
                    </div>


                    <div class="input_group">
                        <p class="lk_input_p">Сумма по договору</p>
                        <input required v-model="client.contract_amount" type="number" class="lk_input"
                            placeholder="Введите сумму по договору">
                    </div>

                    <div class="input_group" style="text-align: left;">
                        <p class="lk_input_p">Оплачено по договору</p>
                        <input required v-model="client.paid" :max="client.contract_amount" type="number" class="lk_input"
                            placeholder="Введите оплаченную сумму">

                    </div>

                    <div class="input_group">
                        <p class="lk_input_p">Вопрос обращения</p>
                        <textarea @input='handleInput' required type="text" class="lk_input"
                            style="height:140px;align-items: top;padding-top:20px;" placeholder="Введите вопрос обращения"
                            v-model="client.additionally" maxlength="150"></textarea>
                    </div>

                    <div class="input_group" style="margin:auto">
                        <button class="lk__btn">
                            СОХРАНИТЬ ИЗМЕНЕНИЯ
                        </button>
                        <v-dialog width="300px" v-model="getModal" @click:outside="setModal(false)">
                            <template v-slot:default="{ }">
                                <div>
                                    <form class="modal" @submit.prevent="updateClient()">
                                        <h2>Продолжить?</h2>
                                        <div class="buttons">
                                            <v-btn :loading="getLoading" type="submit"
                                                color="var(--green)"><span>Сохранить</span></v-btn>
                                            <v-btn color="var(--red)" @click="setModal(false)"><span>Выйти</span></v-btn>
                                        </div>
                                    </form>
                                </div>
                            </template>
                        </v-dialog>

                        <p class="bottom_links" @click="close()">отменить редактирование и выйти</p>

                        <v-dialog width="300px">
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" variant="text">
                                    <p class="bottom_links" style="color:var(--red);">удалить клиента</p>
                                </v-btn>
                            </template>
                            <template v-slot:default="{ isActive }">
                                <div>
                                    <form class="modal" @submit.prevent="deleteClient()">
                                        <h2>Продолжить?</h2>
                                        <div class="buttons">
                                            <v-btn :loading="getLoading" type="submit"
                                                color="var(--red)"><span>Удалить</span></v-btn>
                                            <v-btn color="var(--green)"
                                                @click="isActive.value = false"><span>Выйти</span></v-btn>
                                        </div>
                                    </form>
                                </div>
                            </template>
                        </v-dialog>

                    </div>

                </div>



            </form>

        </div>

    </div>
</template>

<style scoped>
.download_contract-text {
    padding: 0 5px;
}

.status_contract .lk_input_p {
    border-radius: 12px;
    padding: 5px 20px;
}

.status_contract input {
    margin: 10px;
    margin-top: 5px;
}

.input-file {
    color: white;
}

.lk__btn {
    margin-top: 13px;
    max-width: 350px;
}
</style>