<script>
import axios from '@/roleGuard'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { baseURL } from "@/config";
import { generate_password, download, createNewFormData, passwordIsValid } from '@/utils';

import vFlags from "../../v-flags.vue"
import vNavigationPanel from "@/components/v-navigation-panel.vue";
import { vDialog, vBtn } from 'vuetify'


export default {
    name: "adminChangeclient",
    components: {
        vFlags, vNavigationPanel,
        vDialog, vBtn
    },
    data() {
        return {
            title: "Изменение данных юриста",
            employee: {},
            file_pdf_contract: "",

            isPasswordValid: true,

        }
    },
    computed: {
        ...mapGetters('main', ['allCities']),
        ...mapGetters('request', ['getLoading', 'getModal']),

    },

    methods: {
        ...mapActions('main', ['loadCities']),
        ...mapMutations("request", ['setResponse', 'setLoading', 'setModal']),
        downloadUserFile() {
            const id = this.employee.user
            const url = baseURL + `user/${id}/download-file`
            download(url)
        },

        close(){
            if(window.history.length > 2){
                this.$router.go(-1)
            }
            else{
                this.$router.push("/")
            }
        },
        generateNewPassword() {
            const password = generate_password();
            this.employee.password = password;
        },
        handleFileUpload(event) {
            this.employee.file_pdf_contract = event.target.files[0];
        },
        fetchEmployee(id) {
            const url = baseURL + `employee/${id}`

            axios.get(url)
                .then(response => {
                    this.employee = response.data
                    this.employee.date_of_birth = this.employee.date_of_birth.split('T')[0]
                    this.employee.date_start_contract = this.employee.date_start_contract.split('T')[0]
                    this.file_pdf_contract = this.employee.file_pdf_contract
                    this.employee.file_pdf_contract = ''
                    this.employee.password = ''
                }
                )
                .catch(e => 
                {
                    const status = e.response.status
                    if (status==403){
                        this.$router.push('/access-denied')
                    }
                    else if(status == 404){
                        this.$router.push({name:'not-found'})
                    }
                
                })
        },


        updateEmployee() {
        
            if (this.employee.password) {
                if (!passwordIsValid(this.employee.password)) {
                    this.setModal(false)
           
                    this.setResponse({ status: false, message: 'Слишком простой пароль' })
                    return
                }
            }
            this.setLoading(true)
            const url = baseURL + `user/settings/${this.employee.user}`;
            const data = {
                email: this.employee.email,
                first_name: this.employee.name,
                number_phone: this.employee.number_phone,
                password: this.employee.password
            }
            const formData = createNewFormData(data,[])
            axios.put(url, formData)
                .then((response) => {
                    // this.setResponse({ status: true, message: response.data.message })
                    const new_data = createNewFormData(this.employee, [])
                    const id = this.employee.id
                    const new_url = baseURL + `employee/${id}/update`
                    axios.put(new_url, new_data)
                    .then(()=>{
                        this.setResponse({ status: true, message: response.data.message })
                    })
                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                    this.setModal(false)
                })

        },
        deleteEmployee() {
            this.setLoading(true)
            const id = this.$route.params.employeeId;
            const url = baseURL + `employee/${id}/destroy`

            axios.delete(url)
                .then(() => {
                    this.$router.push('/'),
                        this.setResponse({ status: false, message: "Юрист удалён" })
                })
                .catch((e) => {
                    this.setResponse({ status: false, message: e.response.data.message })
                })
                .finally(() => {
                    this.setLoading(false)
                })
        },
        checkMinLength() {
            const inputValue = this.employee.number_phone;
            const inputElement = this.$refs.phoneInput;

            if (inputValue.length < 18) {
                inputElement.setCustomValidity('Введите номер телефона полностью.');
            } else {
                inputElement.setCustomValidity('');
            }

            inputElement.reportValidity(); // Для показа сообщения об ошибке валидации
        }


    },
    created() {
        const id = this.$route.params.employeeId;
        this.fetchEmployee(id)
        this.loadCities()
    }
}

</script>

<template>
    <div class="lk-page">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel></vNavigationPanel>

        <div>
            <form action="" @submit.prevent="setModal(true)">
                <div class="content">
                    <div class="content-left">
                        <div class="input_group">
                            <p class="lk_input_p">ФИО</p>
                            <input @input="handleInput" v-model="employee.name" type="text" class="lk_input"
                                placeholder="Введите ФИО" required>
                        </div>

                        <div class="input_group">
                            <p class="lk_input_p">E-mail</p>
                            <input @input="handleInput" v-model="employee.email" type="text" class="lk_input"
                                placeholder="E-mail" required>
                        </div>
                        <div class="input_group">
                            <p class="lk_input_p">Трудовой договор</p>
                            <input @input="handleInput" v-model="employee.number_contract" type="text" class="lk_input"
                                placeholder="№ " required>
                        </div>


                        <div class="input_group" style="text-align:left;">
                            <p class="lk_input_p">Дата заключения договора</p>
                            <input @input="handleInput" v-model="employee.date_start_contract" type="date" max='2050-01-01'
                                min='1900-01-01' class="lk_input" required>


                        </div>
                        <div class="input_group" style="text-align:left; margin:10px 0">
                            <p class="lk_input_p">Файл договора</p>
                            <div class="download_contract" v-if="file_pdf_contract">

                                <span class="download_contract-text" ref="fileText"> {{ file_pdf_contract
                                }}</span>
                                <p @click="downloadUserFile()" class="download_contract-icon" style="float:right;"></p>
                            </div>
                        </div>


                        <div class="input_group" style="text-align:left; margin:10px 0">
                            <p class="lk_input_p">Прикрепить файл договора</p>
                            <label for="pdf" class="custom-file-upload">

                                <span v-if="employee.file_pdf_contract && employee.file_pdf_contract.name">{{
                                    employee.file_pdf_contract.name }}</span>
                                <span v-else>Выбрать файл</span>
                                <input class="input-file" style="display: none;" type="file" id="pdf" name="file"
                                    accept=".doc, .xlsx, .pdf, .xls, .docx" @change="handleFileUpload" ref="fileInput">

                            </label>
                        </div>



                    </div>

                    <div class="content-right">
                        <div class="input_group">
                            <p class="lk_input_p">Номер телефона</p>
                            <input @input="handleInput" v-phone v-model="employee.number_phone" type="text" class="lk_input"
                                placeholder="+79819191251" required>
                        </div>
                        <div class="input_group">
                            <p class="lk_input_p">Дата рождения</p>
                            <input @input="handleInput" v-model="employee.date_of_birth" type="date" max='2050-01-01'
                                min='1900-01-01' class="lk_input" required>
                        </div>

                        <div class="input_group">
                            <p class="lk_input_p" @click="generateNewPassword()">Пароль <span style="float:right;" href=""
                                    class="bottom_links">сгенеририровать
                                    новый</span></p>
                            <input @input="handleInput" v-model="employee.password" type="text" class="lk_input"
                                ref="password_input" placeholder="Введите новый пароль">
                        </div>

                        <div class="input_group">
                            <p class="lk_input_p">Город работы</p>
                            <!-- <input @input="handleInput" v-model = ""type="text" class="lk_input" placeholder="Moscow" value = "Чита"> -->

                            <div class="costum-select">

                                <select name="" id="" class="" v-model="employee.city">
                                    <option v-for="city in allCities" :key="city.id" :value="city.city">{{ city.city }}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="input_group" style="text-align:left">
                            <p class="lk_input_p">Дополнительно</p>
                            <textarea @input="handleInput" type="text" v-model="employee.additionally" class="lk_input"
                                style="height:140px;align-items: top;padding-top:20px;"
                                placeholder="Дополнительня информация" required></textarea>

                        </div>
                    </div>
                </div>


                <div class="input_group" style="margin:auto">
                    <button class="lk__btn">СОХРАНИТЬ ИЗМЕНЕНИЯ</button>

                    <v-dialog width="300px" v-model="getModal" @click:outside="setModal(false)">
                        <template v-slot:default="{ }">
                            <div>
                                <form class="modal" @submit.prevent="updateEmployee()">
                                    <h2>Продолжить?</h2>
                                    <div class="buttons">
                                        <v-btn :loading="getLoading" type="submit"
                                            color="var(--green)"><span>Сохранить</span></v-btn>
                                        <v-btn color="var(--red)" @click="setModal(false)"><span>Выйти</span></v-btn>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </v-dialog>
                    <p class="bottom_links" @click="close()">отменить редактирование и выйти</p>

                    <v-dialog width="300px">
                        <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" variant="text">
                                <p class="bottom_links" style="color:var(--red);">удалить юриста</p>
                            </v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                            <div>
                                <form class="modal" @submit.prevent="deleteEmployee()">
                                    <h2>Продолжить?</h2>
                                    <div class="buttons">
                                        <v-btn :loading="getLoading" type="submit"
                                            color="var(--red)"><span>Удалить</span></v-btn>
                                        <v-btn color="var(--green)"
                                            @click="isActive.value = false"><span>Выйти</span></v-btn>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </v-dialog>
                </div>
            </form>

        </div>


    </div>
</template>
<style
scoped>
.input-file {
    margin-top: 17px;
}

.download_contract-text {
    padding: 0 10px;
}
</style>