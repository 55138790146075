<script>
import vFlags from "@/components/v-flags.vue"
import rowsForspisok from "@/components/rows-forspisok.vue"
import axios from "@/roleGuard"
import { baseURL } from "@/config"
import { mapGetters, mapActions } from 'vuex'
import { clearFilter, isEmployee, isAdmin, formatName } from '@/utils'
import Paginate from 'vuejs-paginate-next'
import vNavigationPanel from "@/components/v-navigation-panel.vue"
import vSkeletonLoader from 'vuetify'

export default {
    name: "clientSpisok",
    components: {
        vFlags,
        rowsForspisok, vSkeletonLoader,
        Paginate, vNavigationPanel,
    },
    data() {
        return {
            title: "Список клиентов",
            clients: [],
            filtered_clients: [],
            currentPage: 1,
            clientPerPage: 10,
            additionally_data: {},
            filter: {
                date: false,
                lowest_rating: false,
                contract_sum: false,
                obligations: false
            },
            stats: {},
            my: {}
        }
    },
    computed: {
        ...mapGetters('main',['getUser']),
        displayedClient() {
            if (this.clients.length < 1) { return }
            const startIndex = (this.currentPage - 1) * this.clientPerPage;
            const endIndex = startIndex + this.clientPerPage;
            return this.filtered_clients.slice(startIndex, endIndex);
        },
        totalPages() {
            const cl = this.filtered_clients
            if (cl) {
                if (cl.length) {
                    return Math.ceil(cl.length / this.clientPerPage);
                }
                return 0
            }
            return 0
        },
    },

    methods: {
        ...mapActions('main',['GET_USER_INFO']),

        removeFilter() {
            clearFilter(this.filter)
            this.filtered_clients = this.clients
            this.currentPage = 1
        },

        close() {
            if (isEmployee()) {
                this.$router.push("/employee")
            }
            else if (isAdmin()) {
                this.$router.push("/admin")
            }
        },
        goToPage(pageNumber) {
            this.currentPage = pageNumber;
        },
        formatNameComponent(name) {
            return formatName(name)
        },

        //фильтры
        ratingFilter() {
            const _ = require('lodash');
            const sort = _.sortBy(this.filtered_clients, ['rating']);
            this.filtered_clients = sort
            this.currentPage = 1
        },
        filterByDate() {
            const _ = require('lodash');
            const sort = _.sortBy(this.filtered_clients, ['date_start_contract']);
            this.filtered_clients = sort
            this.currentPage = 1
        },
        filterBySum() {
            const _ = require('lodash');
            const sort = _.sortBy(this.filtered_clients, ['contract_amount']);
            this.filtered_clients = sort.reverse()
            this.currentPage = 1
        },
        filterByDolg() {
            const _ = require('lodash');
            const sort = _.sortBy(this.filtered_clients, [function (user) { return user.contract_amount - user.paid }]);
            this.filtered_clients = sort.reverse()
            this.currentPage = 1
        },

        //получаем своих клиентов
        getMyClients(id) {
            const url = baseURL + `employee/${id}/client-list`;
            axios.get(url)
                .then((response) => { this.clients = response.data, this.filtered_clients = response.data })
                .catch(e => console.log(e))
        },
        //дополнительный данные
        loadAdditionallyData(id) {
            const url = baseURL + `employee/${id}`
            axios.get(url)
                .then(response => {
                    this.additionally_data = response.data
                    this.additionally_data.his_clients = response.data.his_clients
                }
                )
                .catch(e => console.log(e))
        },
        loadStats(id) {
            const url = baseURL + `employee/${id}/stats`
            axios.get(url)
                .then(response => this.stats = response.data)
                .catch(e => console.log(e))
        }
    },
    created() {
        let id;

        if (this.$route.params && this.$route.params.employeeId) {
            //если зашли со стороны админа.
            id = this.$route.params.employeeId
        }
        else {
            this.my = this.getUser
            id = this.my.profile.id
            //если зашли от имени юриста
        }

        this.getMyClients(id)
        this.loadStats(id)
        this.loadAdditionallyData(id);
    },
}
</script>

<template>
    <div class="lk-page"><!--v-if="my.profile"-->
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel></vNavigationPanel>

        <div style="text-align:left; margin:20px 0;">
            <h2>Всего клиентов: {{ this.filtered_clients.length || 0 }} </h2>

            <div class="filter">
                <p>фильтр списка:</p>
                <div>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.date" @change="filterByDate()">
                        <label for="ch1">дата</label>
                    </span>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.lowest_rating"
                            @change="ratingFilter()">
                        <label for="ch1">низшая оценка</label>
                    </span>
                    <span>
                        <input type="checkbox" class="checkbox" @change="filterBySum()" v-model="filter.contract_sum">
                        <label for="ch1">сумма</label>
                    </span>
                    <span>
                        <input type="checkbox" id="ch1" v-model="filter.obligations" @change="filterByDolg()"
                            class="checkbox">
                        <label for="ch1">долг</label>
                    </span>
                    <span class="remove-filter" @click="removeFilter()">очистить фильтр</span>
                </div>

            </div>
        </div>

        <p v-if="!this.filtered_clients.length" class="no-result">Ничего не найдено</p>
        <div class="table" v-if="this.filtered_clients.length">
            <table>
                <tr>
                    <th>№</th>
                    <th>ФИО</th>
                    <th>дата</th>
                    <th>оценка</th>
                    <th>сумма</th>
                    <th>долг</th>
                </tr>

                <rowsForspisok v-for="(client, index) in displayedClient" :key="client.id" :client='client'
                    :rows="index + 1">
                </rowsForspisok>
            </table>

            <Paginate v-if="this.filtered_clients.length > this.clientPerPage" :page-range='2' :initial-page="currentPage"
                :page-count="totalPages" :click-handler="goToPage" :active-class="'nav-activ-a'" :break-view-text="'...'"
                :prev-text="'<'" :next-text="'>'" :container-class="'nav-cont'" :page-class="'nav-li'"
                :page-link-class="'nav-a'" :prev-link-class="'nav-a'" :prev-class="'nav-li'" :next-link-class="'nav-a'"
                :next-class="'nav-li'">

            </Paginate>
        </div>



        <div class="info content" style="text-align:left;">
            <div class="content-left">
                <table class="tables_with_info">
                    <tr>
                        <th>Статистика:</th>
                    </tr>

                    <tr>
                        <td>Оценка: {{ additionally_data.rating || 0 }} </td>
                    </tr>
                    <tr>
                        <td>Клиентов: {{ additionally_data.active_contract || 0 }}</td>
                    </tr>
                    <tr>
                        <td>Сумма: {{ additionally_data.total_sum_contract || 0 }}</td>
                    </tr>

                    <tr>
                        <td style="border-bottom:0">Долг: {{ additionally_data.obligation || 0 }}</td>
                    </tr>
                </table>
            </div>

            <div class="content-right">
                <table v-if="stats" class="tables_with_info">
                    <tr>
                        <th>Информация:</th>
                    </tr>

                    <tr>

                        <td>
                            <span>Отправлено сообщений:</span>
                            <v-skeleton-loader width="45%" v-if="stats.count_send_message == undenfined" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else>{{ stats.count_send_message }}</span>
                        </td>
                    </tr>

                    <tr>


                        <td>
                            <span>Новый клиент:</span>
                            <v-skeleton-loader width="55%" v-if="!stats.new_client" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else-if="!stats.new_client.name">Информация не найдена</span>

                            <router-link v-else :to="{ name: 'watchClient', params: { clientId: stats.new_client.id } }">
                                {{ formatNameComponent(stats.new_client.name) }}
                            </router-link>
                        </td>

                    </tr>
                    <tr>

                        <td>
                            <span>Высшая оценка:</span>
                            <v-skeleton-loader width="55%" v-if="!stats.highest_rating" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else-if="!stats.highest_rating.name">Информация не найдена</span>

                            <router-link v-else
                                :to="{ name: 'watchClient', params: { clientId: stats.highest_rating.id } }">
                                {{ formatNameComponent(stats.highest_rating.name) }}
                            </router-link>
                        </td>

                    </tr>
                    <tr>


                        <td style="border-bottom: 0;">
                            <span>Низшая оценка:</span>
                            <v-skeleton-loader width="55%" v-if="!stats.lowest_rating" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else-if="!stats.lowest_rating.name">Информация не найдена</span>

                            <router-link v-else :to="{ name: 'watchClient', params: { clientId: stats.lowest_rating.id } }">
                                {{ formatNameComponent(stats.lowest_rating.name) }}
                            </router-link>
                        </td>

                    </tr>

                </table>
            </div>

        </div>
    </div>
</template>


<style scoped>
.filter {
    text-align: left;
    border-radius: 10px;
    font-size: 18px;
    margin: 20px 0px;
    padding: 5px;
}


.info>div {
    min-width: 48.5%;
}

.info {
    display: flex;
    margin-top: 20px;

}

.flex {
    display: flex;

}

.filter__btn {
    display: inline-block;
    display: flex;
    max-width: 50%;
    justify-content: space-between;
    background: var(--mongo);
    padding: 7px;
    border-radius: 10px;
    margin-right: 20px;
}

.title {
    padding-top: 5px;
}

.costum-select {
    margin-bottom: 0;
    width: 200px;
    height: 30px;
    margin-left: 10px;
}

.costum-select select {
    font-size: 15px;
    height: 30px;
}</style>