export default{
    namespaced:true,
    state:{
        status:null,
        message:'',
        loading:false,
        modal:false,
    },
    getters:{
        getStatusRequest(state){return state.status},
        getMessageRequest(state){return state.message},
        getLoading(state){return state.loading},
        getModal(state){return state.modal}
    },
    mutations:{
        setResponse(state, response){
            state.status = response.status
            state.message = response.message
        },
        setLoading(state, bool){
            state.loading = bool
        },
        setModal(state, bool){
            state.modal = bool
        }
        
    }
}