<script>
export default {
    name: "vFooter"
}

</script>

<template>
    <footer>
        <div class="footer">
            <div class="b">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЗАБАЙКАЛЬСКИЙ ЮРИСТ"</div>
            Не является публичной офертой. Все права защищены © 2016-2023<br>
            <a href="https://zabyurist.ru/" target="blank">ZABYURIST.RU</a> | 8-800-7777-830<br>
            <img class="logo" src="@/assets/img/logo2.png" alt="ZABYURIST.RU - Услуги для бизнеса" width="150px"
                itemprop="contentUrl">
        </div>
    </footer>
</template>

<style scoped>
.footer {
    text-align: center;
    font-size: 15px;
    padding: 25px 0;
    font-family: regular;
    width:100%;
    max-width: 1000px;
    margin:0 auto
}

img {
    width: 150px;
}

.b {
    font-family: pro;
    color:var(--blue)
}

.footer a {
    color: #2e4966;
    text-decoration: underline;
}

.footer img {
    padding-top: 25px;
}</style>