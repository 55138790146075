<script>
import axios from "@/roleGuard"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { clearFilter, formatName } from "@/utils"
import { baseURL } from "@/config";


import vFlags from "@/components/v-flags.vue"
import rowsForspisok from "../../rows-forspisok.vue"
import Paginate from 'vuejs-paginate-next'
import vNavigationPanel from "@/components/v-navigation-panel.vue";
import vSkeletonLoader from "vuetify"

export default {
    name: "clientAdminpisok",
    components: {
        vFlags,
        rowsForspisok, Paginate, vNavigationPanel, vSkeletonLoader
    },
    data() {
        return {
            title: "Список клиентов",
            clients: [],
            currentPage: 1,
            clientPerPage: 10,
            filter: {
                city: '',
                employee: '',
                date: false,
                lowest_rating: false,
                contract_sum: false,
                obligations: false
            },
            table1: {}, //первая таблица с информацие внизу страницы
            table2: {}
        }
    },
    computed: {
        ...mapGetters('main', ['allClients', 'getCompanyInfo', 'allCities', 'allEmployees', 'getFilteredClients']),

        displayedClient() {
            const startIndex = (this.currentPage - 1) * this.clientPerPage;
            const endIndex = startIndex + this.clientPerPage;
            return this.getFilteredClients.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.getFilteredClients.length / this.clientPerPage);
        }
    },
    methods: {
        ...mapMutations('main', ['setFilteredClients']),
        ...mapActions('main', ['GET_INFO_COMPANY', 'GET_EPLOYEES_FROM_API', 'GET_CLIENTS_FROM_API', 'loadCities']),



        goToPage(pageNumber) {
            this.currentPage = pageNumber;
        },

        formatNameComponent(name) {
            return formatName(name)
        },
        removeFilter() {
            clearFilter(this.filter)
            this.filter.city = ""
            this.filter.employee = ""
            this.setFilteredClients(this.allClients)
            this.currentPage = 1
        },

        ratingFilter() {

            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredClients, ['rating']);
            this.setFilteredClients(sort)
            this.currentPage = 1
        },
        filterByDate() {

            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredClients, ['date_start_contract']);
            this.setFilteredClients(sort)
            this.currentPage = 1
        },
        filterBySum() {

            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredClients, ['contract_amount']);
            this.setFilteredClients(sort.reverse())
            this.currentPage = 1
        },
        filterByDolg() {

            const _ = require('lodash');
            const sort = _.sortBy(this.getFilteredClients, [function (user) { return user.contract_amount - user.paid }]);
            this.setFilteredClients(sort.reverse())
            this.currentPage = 1
        },
        filterByCity() {

            const _ = require('lodash');
            const sort = _.filter(this.allClients,
                ['city', this.filter.city]);

            this.setFilteredClients(sort)
            this.currentPage = 1
        },
        filterByEmployee() {

            const _ = require('lodash');
            const sort = _.filter(this.allClients,
                ['lawyer', this.filter.employee.id]);
            this.setFilteredClients(sort)
            this.currentPage = 1
        },
        GET_INFO_COMPANY_FROM_API(city) {
            this.table1 = {}
            this.table2 = {}
            const url = baseURL + 'company/info'

            const params = {
                "city": city
            }
            axios.get(url, { params: params })
                .then((response) => {

                    this.table1 = response.data.table1
                    this.table2 = response.data.table2
                })
                .catch(() => {
                    this.table1 = {}
                    this.table2 = {}
                }
                )
        }
    },

    created() {
        this.GET_CLIENTS_FROM_API()
        this.loadCities()
        this.GET_EPLOYEES_FROM_API()
    },
    mounted() {
        this.GET_INFO_COMPANY_FROM_API('')
    },
    watch: {
        'filter.city': {
            handler(newValue) {
                this.GET_INFO_COMPANY_FROM_API(newValue)
            },
            deep: true,
        },
        'filter.employee': {
            handler(newValue) {
                if (newValue) {
                    this.filter.city = newValue.city
                }
            },
            deep: true,
        }
    }
}
</script>

<template>
    <div class="lk-page" v-if="allClients">
        <vFlags :text_flags="title"></vFlags>
        <vNavigationPanel></vNavigationPanel>


        <div style="text-align:left; margin:20px 0;">
            <h2>Всего клиентов: {{ allClients.length }}</h2>

            <div class="filter">
                <p>фильтр списка:</p>

                <div class="flex">
                    <div class="filter__btn">
                        <span class="title">выбрать город</span>
                        <div class="costum-select">
                            <select name="" id="" class="" v-model='filter.city' @change="filterByCity">
                                <option disabled value="">все города</option>
                                <option v-for="city in allCities" :key="city.id" :value="city.city">{{ city.city }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="filter__btn">
                        <span class="title">специалист</span>
                        <div class="costum-select">
                            <select v-model="filter.employee" @change="filterByEmployee()">
                                <option value="" disabled>все</option>
                                <option v-for="lawyer in allEmployees" :key="lawyer.id" :value="lawyer">{{ lawyer.name }}
                                </option>
                            </select>
                        </div>
                    </div>


                </div>

                <div>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.date" @change="filterByDate()">
                        <label for="ch1">дата</label>
                    </span>
                    <span>
                        <input type="checkbox" id="ch1" class="checkbox" v-model="filter.lowest_rating"
                            @change="ratingFilter()">
                        <label for="ch1">низшая оценка</label>
                    </span>
                    <span>
                        <input type="checkbox" class="checkbox" @change="filterBySum()" v-model="filter.contract_sum">
                        <label for="ch1">сумма</label>
                    </span>
                    <span>
                        <input type="checkbox" id="ch1" v-model="filter.obligations" @change="filterByDolg()"
                            class="checkbox">
                        <label for="ch1">долг</label>
                    </span>

                    <span class="remove-filter" @click="removeFilter()">очистить фильтр</span>
                </div>
            </div>
        </div>

        <p v-if="!this.getFilteredClients.length" class="no-result">Ничего не найдено</p>

        <div class="table" v-if="this.getFilteredClients.length && this.allClients">
            <table>
                <tr>
                    <th>№</th>
                    <th>ФИО</th>
                    <th>дата</th>
                    <th>оценка</th>
                    <th>сумма</th>
                    <th>долг</th>
                </tr>

                <rowsForspisok v-for="(client, index) in displayedClient" :key="client.id" :client='client'
                    :rows="index + 1">
                </rowsForspisok>
            </table>
        </div>


        <Paginate v-if="this.getFilteredClients.length > this.clientPerPage" :page-range='1' :initial-page="currentPage"
            :page-count="totalPages" :click-handler="goToPage" :active-class="'nav-activ-a'" :break-view-text="'...'"
            :prev-text="'<'" :next-text="'>'" :container-class="'nav-cont'" :page-class="'nav-li'"
            :page-link-class="'nav-a'" :prev-link-class="'nav-a'" :prev-class="'nav-li'" :next-link-class="'nav-a'"
            :next-class="'nav-li'">
        </Paginate>

        <div class="info content" style="text-align:left;">
            <div class="content-left">
                <table v-if="getCompanyInfo" class="tables_with_info">
                    <tr>
                        <th>Статистика:</th>
                    </tr>

                    <tr>

                    </tr>
                    <tr>
                        <td>
                            <span>Оценка:</span>
                            <span v-if="table1.avg_rating_clients != undenfined">{{ table1.avg_rating_clients }}</span>
                            <v-skeleton-loader v-else class="sc" type="list-item"></v-skeleton-loader>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>Клиентов:</span>
                            <span v-if="table1.count_client != undenfined">{{ table1.count_client }}</span>
                            <v-skeleton-loader v-else class="sc" type="list-item"></v-skeleton-loader>
                        </td>
                    </tr>
                    <tr>

                        <td>
                            <span>Сумма:</span>
                            <span v-if="table1.summ_contract != undenfined">{{ table1.summ_contract }}</span>
                            <v-skeleton-loader v-else class="sc" type="list-item"></v-skeleton-loader>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-bottom: 0;">
                            <span>Долг:</span>
                            <span v-if="table1.obligation != undenfined">{{ table1.obligation }}</span>
                            <v-skeleton-loader v-else class="sc" type="list-item"></v-skeleton-loader>
                        </td>

                    </tr>
                </table>
            </div>

            <div class="content-right">
                <table class="tables_with_info">
                    <tr>
                        <th>Информация:</th>
                    </tr>

                    <tr>
                        <td>
                            <span>Отправлено сообщений:</span>
                            <span v-if="table2.count_messages != undenfined">{{ table2.count_messages }}</span>
                            <v-skeleton-loader width="45%" v-else class="sc" type="list-item"></v-skeleton-loader>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>Новый клиент:</span>
                            <v-skeleton-loader width="55%" v-if="!table2.last_client" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else-if="!table2.last_client.name">Информация не найдена</span>

                            <router-link v-else :to="{ name: 'watchClient', params: { clientId: table2.last_client.id } }">
                                {{ formatNameComponent(table2.last_client.name) }}
                            </router-link>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <span>Высшая оценка:</span>
                            <v-skeleton-loader width="55%" v-if="!table2.client_highest_rating" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else-if="!table2.client_highest_rating.name">Информация не найдена</span>

                            <router-link v-else
                                :to="{ name: 'watchClient', params: { clientId: table2.client_highest_rating.id } }">
                                {{ formatNameComponent(table2.client_highest_rating.name) }}
                            </router-link>
                        </td>
                    </tr>


                    <tr>
                        <td style="border-bottom: 0;">
                            <span>Низшая оценка:</span>
                            <v-skeleton-loader width="55%" v-if="!table2.client_lowest_rating" class="sc"
                                type="list-item"></v-skeleton-loader>
                            <span v-else-if="!table2.client_lowest_rating.name">Информация не найдена</span>

                            <router-link v-else
                                :to="{ name: 'watchClient', params: { clientId: table2.client_lowest_rating.id } }">
                                {{ formatNameComponent(table2.client_lowest_rating.name) }}
                            </router-link>
                        </td>
                    </tr>




                </table>
            </div>

        </div>
    </div>
</template>

<style scoped>
.filter {
    text-align: left;
    border-radius: 10px;
    font-size: 18px;
    margin: 20px 0px;
    padding: 5px;
}


.info>div {
    min-width: 48.5%;
}


.flex {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
}

.filter__btn {
    /* display: inline-block; */
    display: flex;
    width: 370px;
    justify-content: space-between;
    background: var(--orange);
    padding: 5px;
    border-radius: 13px;
    flex-wrap: wrap;
    grid-gap: 5px;
    align-items: center;
}

.title {
    padding: 5px;
    margin:auto
}

@media (max-width:480px) {
    .costum-select {
    /* margin-bottom: 0; */
    width: 100%;
    height: 35px;
}
}
@media (min-width:480px) {
    .costum-select {
    /* margin-bottom: 0; */
    width: 200px;
    height: 35px;
}


}
.costum-select select {
    font-size: 15px;
    height: 35px;
}</style>