<script>
import mainSidebar from "./main-sidebar.vue"
import { breakPoints, getWidthWindow } from '@/utils'
import vFooter from "@/components/v-footer.vue"
// import { mapGetters } from "vuex"
import vStatus from "./v-status.vue"

export default {
    name: 'mainWrapper',
    components: {
        mainSidebar,
        vFooter,
        vStatus
    },
    data() {
        return {
            window: { width: 0, height: 0 },
            sidebar: true,
            openmenu: false,
            burger_text: true,
            orientation: '' // window.matchMedia("(orientation: landscape)").matches ? 'landscape' : 'portrait'
        }
    },
    computed: {
        imageSize() {
            if (!this.sidebar) {
                return 'width:100%;max-width:370px;'
            }
            return 0
        },

        isFlex() {
            let width = this.window.width
            if (this.openmenu){
                    return { position:'fixed'}
                }
            if (width > breakPoints[2]) {
               
                return "display:flex;"
            }

            return "display:block;"
        },
        isVisible() {
            if (this.openmenu) {

                return "visible"
            }
            return ''
        },
        isLandscape() {
            return this.orientation === 'landscape';
        },
     
    },
    methods: {

        handleClickOutside(event) {
            const phon = this.$refs.phon; // Получаем ссылку на элемент сайдбара
            if (phon && !phon.contains(event.target)) {
                return
            } else {
                this.openmenu = false;
                phon.style = "display:none"
                if (this.$refs.burger_text) {
                    this.$refs.burger_text.innerText = "ОТКРЫТЬ МЕНЮ"
                }

            }
        },

        openMenu() {
            if (!this.openmenu) {

                this.openmenu = true;

                this.$refs.burger.style = "position:absolute"
                if (this.burger_text) {
                    this.$refs.burger_text.innerText = "ЗАКРЫТЬ МЕНЮ"
                }
                this.$refs.burger.style = "background-color:grey;color:white"
                this.$refs.phon.style = "display:block; position:fixed"
                return
            }
            this.openmenu = false
            this.$refs.phon.style = "display:none"
            if (this.burger_text) {
                this.$refs.burger_text.innerText = "ОТКРЫТЬ МЕНЮ"
            }
            this.$refs.burger.style = " background-color: rgba(0,0,0,.5);color: white;"
        },

        sidebarStyle() {
            let width = this.window.width
            if (width > breakPoints[2]) {
                this.openmenu = false,
                    this.sidebar = true;
                if (this.$refs.phon) {
                    this.$refs.phon.style = "display:none"
                }
                return
            }
            if (width < breakPoints[2]) {
                this.sidebar = false

            }
            else if (width > breakPoints[2]) {
                this.sidebar = true
            }
            if (width < breakPoints[1] && width > breakPoints[0]) {
                this.burger_text = false
            }
            else {
                this.burger_text = true
            }

        },
        handleResize() {
            this.window.width = getWidthWindow()
            this.sidebarStyle()

        }
        ,
        scrollToTop() {

            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Добавление плавной прокрутки
            });
            this.openmenu = false
            this.$refs.phon.style = "display:none"
            if (this.burger_text) {
                this.$refs.burger_text.innerText = "ОТКРЫТЬ МЕНЮ"
            }
        },

        handleOrientationChange() {
            this.orientation = window.matchMedia("(orientation: landscape)").matches ? 'portrait' :  'landscape';
        },

    },
    created() {

        this.window.width = getWidthWindow()
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
    },
    mounted() {
        this.orientation = window.matchMedia("(orientation: landscape)").matches ? 'landscape' :  'portrait';
        document.body.addEventListener('click', this.handleClickOutside);

        window.addEventListener("orientationchange", this.handleOrientationChange);
    },
    beforeUnmount() {
        document.body.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener("orientationchange", this.handleOrientationChange);
    },
  
}

</script>

<template>
    <div class="phon" ref="phon"></div>
    <div class="main-wrapper" ref="mainWrapper" :style="isFlex">
        <v-status></v-status>
        <div class="header" v-if="!sidebar">
            <div class="header-image">
                <img class="logo" src="@/assets/img/logo2.png" alt="ZABYURIST.RU - Услуги для бизнеса" :style="imageSize"
                    itemprop="contentUrl">
            </div>
            <div class="burger" :style = "openmenu ?{right:'270px'} :{right:'0'} " ref="burger" @click="openMenu()">
                <p v-if="burger_text" ref="burger_text">ОТКРЫТЬ МЕНЮ</p>
                <div class="burger-line">
                    <p></p>
                    <hr style = "color:white">
                    <p></p>
                    <hr style = "color:white">
                    <p></p>
                    <hr style = "color:white">
                </div>
            </div>
        </div>
        <mainSidebar v-if="sidebar"></mainSidebar>
        <div style="width:100%">
            <router-view class="main-content"></router-view>
            <vFooter></vFooter>
        </div>
    </div>

    <div class="openmenu" :class="isVisible" :style="this.isLandscape ? { position: 'absolute' }  :   { position: 'fixed' }"  ref="openmenu">
       
        <ul class="right-sidebar">
           
            <p class="button" @click="this.$router.push('/authForm'), scrollToTop()">
                ЛИЧНЫЙ КАБИНЕТ
            </p>
          

            <li> <a target='_blank' class="nav-buy_main-page right-sidebar__a" href="https://zabyurist.ru/">Главная
                    страница</a></li>
            <li><a class="right-sidebar__a" target='_blank' href="https://zabyurist.ru/uslugi">Юридические услуги</a></li>
            <li><a class="right-sidebar__a" target='_blank' href="https://zabyurist.ru/business">Услуги для бизнеса</a></li>
            <li><a class="right-sidebar__a" target='_blank' href="https://zabyurist.ru/company">О нашей компании</a></li>
            <li><a class="right-sidebar__a" target='_blank' href="https://zabyurist.ru/credit">Рассрочка на услуги</a></li>
            <li style="border-bottom:none"><a class="right-sidebar__a" target='_blank' href="https://zabyurist.ru/work">Наши
                    сотрудники</a></li>
            <!-- <p class="up" @click="scrollToTop()">подняться на верх</p> -->
            <p></p>
        </ul>

        <div class="new_style">


            <div class="ya-share2 ya-share2_inited" data-services="vkontakte,odnoklassniki,twitter" data-counter="ig"
                style="margin: 10px 0; text-align: center;">
                <div
                    class="ya-share2__container ya-share2__container_size_m ya-share2__container_color-scheme_normal ya-share2__container_shape_normal">
                    <ul class=" new_style-ul">
                        <li class="ya-share2__item ya-share2__item_service_vkontakte"><a class="ya-share2__link"
                                href="https://vk.com/share.php?url=https%3A%2F%2Fzabyurist.ru%2Fuslugi&amp;title=%D0%AE%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&amp;utm_source=share2"
                                rel="nofollow noopener" target="_blank" title="ВКонтакте">
                                <span class="ya-share2__badge">
                                    <span class="ya-share2__icon"></span></span></a></li>
                        <li class="ya-share2__item ya-share2__item_service_odnoklassniki"><a class="ya-share2__link"
                                href="https://connect.ok.ru/offer?url=https%3A%2F%2Fzabyurist.ru%2Fuslugi&amp;title=%D0%AE%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&amp;utm_source=share2"
                                rel="nofollow noopener" target="_blank" title="Одноклассники"><span
                                    class="ya-share2__badge"><span class="ya-share2__icon"></span></span></a></li>
                        <li class="ya-share2__item ya-share2__item_service_twitter"><a class="ya-share2__link"
                                href="https://twitter.com/intent/tweet?text=%D0%AE%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8%20%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD&amp;url=https%3A%2F%2Fzabyurist.ru%2Fuslugi&amp;utm_source=share2"
                                rel="nofollow noopener" target="_blank" title="Twitter"><span class="ya-share2__badge"><span
                                        class="ya-share2__icon"></span></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="ya-rait"><iframe frameborder="0" height="50"
                src="https://yandex.ru/sprav/widget/rating-badge/1386949173" width="150"></iframe></div>
    </div>
</template>

<style scoped >
.new_style {
    max-width: 80px;
    height: 26px;
    /* margin: 20px auto; */
    /* исправление от забюриста */
    margin:auto auto;
    margin-bottom:5px;
}

.new_style-ul {
    display: flex;
    width: 80px;
    justify-content: space-between;
}

.new_style-ul li {
    background-color: var(--dark);
}

.ya-share2__container_size_m {
    font-size: 13px;
}

.right-sidebar__a {
    width: 100%;
    display: block;
    height: 100%;
    padding: 10px;
    font-size: 20px;
    font-family: regular;
}

.right-sidebar li {
    cursor: pointer;
    text-align: left;
    border-bottom: 5px solid #251f1f;

}


.ya-share2__item_service_twitter .ya-share2__badge,
.ya-share2__item_service_twitter .ya-share2__mobile-popup-badge {
    background-color: #00aced;
}

.ya-share2__item_service_vkontakte .ya-share2__icon {
    background-image: url(data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.878 17.304c-5.411 0-8.695-3.755-8.823-9.994h2.74c.086 4.583 2.171 6.528 3.77 6.925V7.31h2.627v3.954c1.542-.17 3.155-1.97 3.698-3.954h2.584c-.414 2.441-2.17 4.24-3.412 4.983 1.242.6 3.24 2.17 4.011 5.01h-2.84c-.6-1.898-2.07-3.369-4.04-3.569v3.57h-.315Z' fill='%23fff'/%3E%3C/svg%3E);
}

.ya-share2__badge {
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    position: relative;
}

.ya-share2__item_service_twitter .ya-share2__icon {
    background-image: url(data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 7.539a6.56 6.56 0 01-1.885.517 3.294 3.294 0 001.443-1.816 6.575 6.575 0 01-2.085.796 3.283 3.283 0 00-5.593 2.994A9.32 9.32 0 015.114 6.6a3.28 3.28 0 001.016 4.382 3.274 3.274 0 01-1.487-.41v.041a3.285 3.285 0 002.633 3.218 3.305 3.305 0 01-1.482.056 3.286 3.286 0 003.066 2.28A6.585 6.585 0 014 17.524 9.291 9.291 0 009.032 19c6.038 0 9.34-5 9.34-9.337 0-.143-.004-.285-.01-.425A6.672 6.672 0 0020 7.538z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E);
}

.ya-share2__list,
.ya-share2__badge,
.ya-share2__icon {
    display: inline-block;
    vertical-align: top;
}

.ya-share2__item_service_odnoklassniki .ya-share2__badge,
.ya-share2__item_service_odnoklassniki .ya-share2__mobile-popup-badge {
    background-color: #f70;
}


.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
}

.ya-share2__list.ya-share2__list_direction_horizontal>.ya-share2__item {
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin: 2px 4px 0 0;
}

.ya-share2__item_service_vkontakte .ya-share2__badge,
.ya-share2__item_service_vkontakte .ya-share2__mobile-popup-badge {
    background-color: #07f;
}


.ya-share2,
.ya-share2 * {
    line-height: normal;
}

.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
}

.ya-share2__item_service_odnoklassniki .ya-share2__icon {
    background-image: url(data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.83 10.77a3.951 3.951 0 0 1-5.604 0 3.951 3.951 0 0 1 0-5.604 3.95 3.95 0 0 1 5.604 0 3.951 3.951 0 0 1 0 5.604Zm-2.802-4.615c-.494 0-.942.2-1.271.53a1.79 1.79 0 0 0 0 2.542 1.789 1.789 0 0 0 2.543 0 1.789 1.789 0 0 0 0-2.543 1.839 1.839 0 0 0-1.272-.53Zm4.168 5.792 1.166 1.59c.059.082.047.188-.036.247-.977.8-2.119 1.33-3.308 1.613l2.249 4.332c.059.13-.024.271-.165.271H13.7a.206.206 0 0 1-.176-.118l-1.496-3.579-1.507 3.567a.181.181 0 0 1-.176.118H7.943c-.141 0-.224-.153-.165-.27l2.249-4.321a8.262 8.262 0 0 1-3.308-1.613c-.071-.06-.095-.177-.036-.248l1.166-1.589c.07-.094.188-.106.27-.035 1.096.93 2.45 1.542 3.898 1.542s2.79-.6 3.897-1.542c.094-.07.223-.06.282.035Z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E);
}

.right-sidebar {
    font-family: regular;
}

.right-sidebar li {
    cursor: pointer;
    border-bottom: 5px solid #251f1f;
}


.right-sidebar li:hover {
    background-color: var(--mongo);
}

.up {
    cursor: pointer;
    color: white;

    background: var(--blue);
    margin: 10px 0;

    border-radius: 9px;
    font-size: 20px;
}

.up:hover {
    opacity: 0.8;
}

.up:hover {
    background: var(--blue);
}

.right-sidebar {
    font-family: regular;
}

.button {
    color: #fff;
    white-space: nowrap;
    background-color: #e63631;
    font-size: 22px;
    padding: 13px;
    width: 100%;
    border: 0;
    border-radius: 11px;
    font-family: pro;
    cursor: pointer;
    margin-bottom: 10px;
}

.button:hover {
    background-color: var(--blue);
}

/* 
.delete {
    color: black;
    font-size: 30px;
    border-bottom: 4px solid black;
} */

.main-wrapper {
    max-width: 1400px;
    width: 100%;
    z-index:100;
    margin: auto;
    justify-content: space-between;

}

.main-content {
    margin: 0 auto;
    min-height: 620px;
}


.openmenu {
    position: fixed;
    right: -400px;
    top: 0;
    z-index: 999;

    background-color: var(--dark);
    text-align: center;
    height: 100%;
    min-height: 700px;
    /* transition:0.5s; анимация для left */
    transition: right 0.5s ease;
    padding: 10px;
    /* padding-top:50px; */
} 

.burger {
    margin: 10px;
    position: fixed;
    z-index: 999;
    right:0;
    transition: right 0.5s ease;
    top: 0;
    font-family: pro;
    cursor: pointer;
    display: flex;
    height: 45px;
    padding: 10px;
    background-color: rgba(0, 0, 0, .5);
    color: white;
    border-radius: 5px;
    max-width: 210px;
    width: auto;
    text-align: center;
}


.visible {
    right: 0;
    position: absolute;
    /* transition: right 4s ease; */
}

.header {
    text-align: center;
    position: relative;
    padding: 20px 0;
}

.header-image {
    padding: 25px;
}


.burger:hover {
    background-color: rgba(0, 0, 0, 0.853);

}

.burger-line {
    width: 20px;
}

.burger p {
    padding: 2px 10px;
    height: 2px;
}


.phon {
    display: none;
    position: fixed;
    z-index: 101;
    top: 0;
    background: black;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    color: black;
}

/*!
	МОИ ДОРАБОТКИ 27.12.23
 */
 /* .main-lft-fixed {
	position: fixed;
	width: inherit
} */

@media all and (max-width: 1260px) {
	.main-wrapper {
		padding: 0 25px
	}

	.main-content {
		display: block;
		min-height: auto
	}

	.header {
		padding: 0;
	}

	.header-image {
		width: 35%;
		margin: 0 auto
	}

	.lk-page {
		border-radius: 13px 13px 9px 9px
	}

	.content-left,
	.content-right {
		max-width: 470px;
	}
}

@media all and (max-width: 1065px) {
	.header-image {
		width: 50%;
		margin: 0 auto
	}


}

@media all and (max-width: 900px) {
	.burger{
		height: 48px
	}

	.burger-line{
		width: 21px;
		margin: 3px
	}
}

@media all and (max-width: 650px) {
	.burger {
		margin: 20px
	}

	.main-wrapper {
		padding: 0px
	}

	.header-image {
		width: 60%;
		padding: 20px
	}

	
/* 
	.filter__btn,
	.filter__btn,
	.filter__btn {
		display: block;
		text-align: center
	}

	.costum-select,
	.costum-select,
	.costum-select {
		display: block;
		width: 100%
	} */
}
</style>