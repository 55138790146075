<template>

    <Transition name="slide-fade">
        <div  v-if="getMessageRequest" class="container" :style="getStyleCont">
            <span class="text">{{ getMessageRequest }}</span>
        </div>
    </Transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { Transition } from 'vue';

export default {
    name: 'vStatus',
    components: {
        Transition
    },
    data() {

        return {
        
        }
    },
    computed: {
        ...mapGetters("request", ['getStatusRequest', 'getMessageRequest']),
        getStyleCont() {
            if (this.getStatusRequest == true) {
                return 'background-color:var(--green)'
            } else{
                return 'background-color:var(--red)'
            }
        },
        
    },
    methods: {
        ...mapMutations('request', ['setResponse']),
        clearPushNotification() {
            setTimeout(() => {
                this.setResponse({s:null,m:''})
            }, 3000)
        }
    },
    watch:{
        getMessageRequest:{
            handler(newValue){
                if(newValue != ''){
                    this.clearPushNotification()
                }
            },
            deep:true
        }
    }

}
</script>

<style scoped>
.container {
    position: fixed;
    top: 100px;
    right: 0;
    z-index:1000;
    padding:10px 2%;
    border-top-left-radius: 9px;
	border-bottom-left-radius: 9px
}
@media (max-width:400px){
    .container{
        top:90px;
    }
}
@media (max-width:1260px) and (min-width: 400px) {
    .container{
        top:200px;
    }
}
.text {
    color: white;
    font-size:1em
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>