<script>
import { baseURL } from '@/config'
import axios from '@/roleGuard'
import { formatName, isEmployee, isAdmin, download, getLocalTime } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: "vMessage",
    emits: ['change'],
    props: {
        message: Object,
        alowedChange: Boolean
    },

    computed: {
        ...mapGetters('main',["getUser", 'getMessages']),
        canEdit() {
            if (isAdmin()) {
                return true
            }
            else if (isEmployee()) {
                return this.message === this.getMessages[0] && this.message.from_user.id === this.getUser.id
            }
            return false
        },

        getDateSend() {
            return getLocalTime(this.message.date_send)
        },
        getDateRead() {
            return getLocalTime(this.message.read_date)
        }
    },
    methods: {
        ...mapMutations('main',['deleteMessageInStore']),
        /**проверка на админа */
        emitToInfoContainer() {
            this.$emit("change", this.message)
        },
        deleteMessage(messageId) {
            /*удаления сообщения */
            const url = baseURL + `messages/${messageId}/delete`;
            axios.delete(url)
                .then(() => {
                    this.deleteMessageInStore(messageId)
                })
                .catch(e => console.log(e))
        },
        downloadFile(messageId) {
            const url = baseURL + `messages/${messageId}/download_file`;
            download(url)
        },
        formated(fullName) {
         return formatName(fullName)
        },
        isAdminUser() {
            return isAdmin()
        },


    },
    created() {

    }

}
</script>


<template>
    <div class="v-message">
        <div>
            <b>
                <p class="from-user" v-if="message.from_user" >От: {{ formated(message.from_user.first_name) }}</p>
            </b>
            <div class="div">
                <div style="display: flex;" v-if="alowedChange">
                    <p class="change-icon" v-if="canEdit" @click="emitToInfoContainer()"></p>
                    <p class="delete-icon" v-if="isAdminUser()" @click="deleteMessage(message.id)"></p>
                </div>
            </div>

        </div>
        <p class="date">Дата {{ getDateSend[0] }}, время {{ getDateSend[1] }} </p>
        <br>
        <p class="message-text">{{ message.text }} </p>
        <br>
        <p class="filename" v-if="message.file" @click="downloadFile(message.id)">{{ message.file }}</p>
        <p class="status">
            <span v-if="!message.read_status" style="background-color: var(--light-rose);">статус: не прочитано</span>
            <span v-if="message.read_status">статус: прочитано в {{ getDateRead[0] }} в {{
                getDateRead[1] }} </span>
        </p>
    </div>
</template>

<style scoped>
.from-user {
    font-size: 17px;
}

.delete_message {
    margin: 10px;
    text-align: left;
}

.v-message {

    background: white;
    color: black;
    text-align: left;
    font-family: regular;
    border-radius: 11px;
    padding: 10px 0 0 10px;
    font-size: 20px;
    margin-bottom: 20px
}

.status {
    text-align: right;
}

.status>span {
    display: inline-block;
    background: var(--light-green);
    padding: 8px;
    border-radius: 11px 0px;
    font-size: 14px
}

.v-message>div {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.change-icon {
    cursor: pointer;
    background: url("@/assets/icons/change-message.png");
    width: 12px;
    height: 12px;
    background-size: cover;
    margin-right: 6px;
}

.delete-icon {
    cursor: pointer;
    background: url("@/assets/icons/delete-message.png");
    width: 12px;
    height: 12px;
    background-size: cover;
}

.date {
    font-size: 17px;
}

.filename {
    cursor: pointer;
    font-size: 14px;
    text-align: right;
    padding: 5px;
}

.filename::after {

    margin: 0 5px;
    content: '\e064';
    font-family: typeicons
}

.message-text {
    padding-right: 10px;
}
</style>